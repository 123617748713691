import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { Loader, showError, showToast } from "../../../constants";
import useTranslation from "../../../hooks/Translation";

const ChangePassword = () => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [changePasswordMutation, { isLoading }] =
    usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required(translation.error.oldPass)
        .min(6, translation.error.passReq),

      newPassword: Yup.string()
        .label("Password")
        .required(translation.error.passwordReq)
        .min(6, translation.error.passReq)
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          translation.auth.passValid
        )
        .notOneOf([Yup.ref("oldPassword"), null], translation.error.nosame),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], translation.auth.passMatch)
        .required(translation.auth.confirmPasswordReq),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };

      try {
        const response = await changePasswordMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast(translation.auth.passChange || "");
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setOldPassword(!oldPassword);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setCnfmPassword(!cnfmPassword);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{translation.auth.changePass} </h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: "8px !important" }}>
                <Grid sx={{ maxWidth: 350 }} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.currentPass}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder={translation.auth.currentPass}
                      fullWidth
                      name="oldPassword"
                      className="text_field"
                      type={oldPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.oldPassword}
                      helperText={
                        formik.touched.oldPassword && formik.errors.oldPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword1}
                              edge="end"
                            >
                              {oldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.newPass}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder={translation.auth.newPass}
                      className="text_field"
                      fullWidth
                      name="newPassword"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      helperText={
                        formik.touched.newPassword && formik.errors.newPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.confmPas}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder={translation.auth.confmPas}
                      fullWidth
                      name="passwordConfirmation"
                      className="text_field"
                      type={cnfmPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.passwordConfirmation}
                      helperText={
                        formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                              edge="end"
                            >
                              {cnfmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default ChangePassword;
