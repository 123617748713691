import LoginPage from "./auth/login";
import SignUpPage from "./auth/signUp";
import DashBoardPage from "./dashBoard";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ManageCourses from "./courses";
import CourseAdd from "./courses/form/add";
import CourseDetail from "./courses/details";
import ManageOrders from "./orders";
import OrderDetail from "./orders/details";
import ManageReview from "./review";
import BuyPlans from "./buyPlans";
import ReviewDetails from "./review/details";
import ProfileSetup from "./profileSetup";
import ManagePromotion from "./promotional";
import AddPromotionalVideo from "./promotional/form";
import ManageVideo from "./manageVideo";
import AddVideo from "./manageVideo/add";
import BuyPlansDetail from "./buyPlans/details";
import PrivacyPolicy from "./privacyPolicy";
import TermsOfUse from "./termsOfUse";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  SignUpPage,
  DashBoardPage,
  ForgotPasswordPage,
  VerifyOtp,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageVideo,
  Settings,
  ManageNotifications,
  AddNotification,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  SubCategories,
  ChangePassword,
  ManageCourses,
  CourseAdd,
  CourseDetail,
  ManageOrders,
  OrderDetail,
  ManageReview,
  BuyPlans,
  ReviewDetails,
  ProfileSetup,
  AddPromotionalVideo,
  ManagePromotion,
  AddVideo,
  BuyPlansDetail,
  PrivacyPolicy,
  TermsOfUse,
};
