import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useLazyGetUserQuery,
  usePostLoginMutation,
  usePostSocialLoginMutation,
} from "../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import app from "../../utils/firebaseKeys";
import LanguageIcon from "@mui/icons-material/Language";
import { useLanguageContext } from "../../context/Language";
import useTranslation from "../../hooks/Translation";
import moment from "moment";

const provider = new GoogleAuthProvider();

const Login = () => {
  const translation = useTranslation() as any;
  const auth = getAuth(app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state, "sdfsd");
  const [getUser] = useLazyGetUserQuery();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [socialLoginMutation, socialData] = usePostSocialLoginMutation();
  const [selectedLang, setSelectedLang] = useState<string>("");

  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const openLang = Boolean(anchorElLang);

  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const { language, changeLanguage } = useLanguageContext();
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const handleLangChange = (val: string) => {
    setToStorage(STORAGE_KEYS.language, val);
    changeLanguage(val);
    setAnchorElLang(null);
  };

  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state !== "profile") {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, [state]);

  const handleSelectedLangChange = (event: SelectChangeEvent) => {
    setSelectedLang(event.target.value as string);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
      phone: "",
      countryCode: "+965",
      countryName: "KW",
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: email
        ? Yup.string()
            .required(translation.error.emailReq)
            .matches(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              translation.error.validEmail
            )
        : Yup.string().notRequired(),
      password: email
        ? Yup.string()
            .label("Password")
            .required(translation.error.passwordReq)
            .min(6, translation.error.passReq)
        : // .matches(
          //   /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
          // )
          Yup.string().notRequired(),
      phone: email
        ? Yup.string().notRequired()
        : Yup.string()
            .required(translation.error.phoneReq)
            .min(6, translation.error.minPhone)
            .max(16, translation.error.maxPhone),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        ...(email ? { email: values.email } : { phone: values.phone }),
        ...(email ? { password: values.password } : {}),
        ...(!email ? { countryCode: values.countryCode } : {}),
        ...(!email ? { countryName: values.countryName } : {}),
        ...(email ? { deviceToken: fcm_token } : {}),
        ...(email ? { deviceType: "WEB" } : {}),
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          if (email) {
            setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
            dispatch(
              setCredentials({
                user: response?.data,
                token: response?.data?.token || "",
              })
            );
            getUserDetails();
            if (formik.values.remember) {
              setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
            } else {
              removeFromStorage(STORAGE_KEYS.credentials);
            }
            if (response?.data?.isProfileComplete) {
              navigate("/dashboard", { replace: true });
            } else if (!response?.data?.isProfileComplete) {
              const state = {
                key: response?.data?.isEmailVerify
                  ? response?.data?.email
                  : response?.data?.phone,
                page: "login",
                type: response?.data?.isEmailVerify ? "email" : "phone",
                ...(!response?.data?.isEmailVerify
                  ? { countryCode: response?.data?.countryCode }
                  : {}),
                ...(!response?.data?.isEmailVerify
                  ? { countryName: response?.data?.countryName }
                  : {}),
              };
              navigate("/profile-setup", { state: state, replace: true });
            }
          } else {
            navigate("/verify-otp", {
              state: {
                page: "login",
                type: "phone",
                key: values.phone,
                countryCode: values.countryCode,
                countryName: values.countryName,
                replace: true,
              },
            });
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const fcm_token = getFromStorage(STORAGE_KEYS.fcmToken);
  //-----google login---//
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const deviceId = navigator.userAgent;
      const data = {
        email: user?.email,
        firstName: user?.displayName?.split(" ")?.[0] || "",
        lastName: user?.displayName?.split(" ")?.[1] || "",
        image: user?.photoURL,
        socialId: user?.uid,
        socialType: "GOOGLE",
        deviceType: "WEB",
        deviceToken: fcm_token,
      };
      try {
        const response = await socialLoginMutation(data).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
          if (response?.data?.isProfileComplete) {
            navigate("/dashboard", { replace: true });
          } else if (!response?.data?.isProfileComplete) {
            const state = {
              key: response?.data?.isEmailVerify
                ? response?.data?.email
                : response?.data?.phone,
              page: "login",
              type: response?.data?.isEmailVerify ? "email" : "phone",
              ...(!response?.data?.isEmailVerify
                ? { countryCode: response?.data?.countryCode }
                : {}),
              ...(!response?.data?.isEmailVerify
                ? { countryName: response?.data?.countryName }
                : {}),
            };
            navigate("/profile-setup", { state: state, replace: true });
          }
          getUserDetails();
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    } catch (error) {
      console.error("Google Login Error:", error);
    }
  };
  //-----google login---//

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element

    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";

      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };

  useEffect(() => {
    change();
  }, [getLang]);

  return (
    <div className="authh">
      <Loader isLoad={isLoading || socialData?.isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure className="authLogo">
              <img src="/static/images/logo.png" alt="" />
            </figure>
            <h2>{translation?.auth.signInTitle} </h2>
            <div className="google_div">
              {email ? (
                <img
                  onClick={() => setEmail(false)}
                  className="google_icon"
                  src="/static/images/call.png"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => setEmail(true)}
                  className="email_fig"
                  src="/static/images/email.png"
                  alt=""
                />
              )}

              <img
                onClick={signInWithGoogle}
                className="google_icon"
                src="/static/images/google.png"
                alt=""
              />
              <img
                className="google_icon"
                src="/static/images/facebook.png"
                alt=""
              />
            </div>
            <h5>
              {translation.auth.usePhone}{" "}
              {email ? translation.auth.email : translation.auth.phone}:
            </h5>

            <Box
              sx={
                {
                  // mr: getLang === "en" ? 2 : 0,
                  // ml: getLang === "ar" ? 2 : 0,
                }
              }
            >
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="selectHover"
              >
                <IconButton
                  // className="notification_btn"
                  aria-controls={openLang ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLang ? "true" : undefined}
                  onClick={handleClickLang}
                >
                  <img
                    src={
                      getLang === "en"
                        ? "/static/images/english.png"
                        : "/static/images/arabic.png"
                    }
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p
                    style={{
                      fontSize: 14,
                      // paddingLeft: "10px",
                      marginLeft: getLang === "en" ? 10 : 0,
                      marginRight: getLang === "ar" ? 10 : 0,
                      fontWeight: "bold",
                    }}
                  >
                    {translation.globals.language}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      marginLeft: getLang === "en" ? 6 : 0,
                      marginRight: getLang === "ar" ? 6 : 0,
                      fontWeight: "bold",
                    }}
                  >
                    {getLang === "en" ? "English" : "عربي"}
                  </p>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
              <Menu
                className="dropd"
                id="basic-menu1"
                anchorEl={anchorElLang}
                open={openLang}
                onClose={() => setAnchorElLang(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
              >
                <MenuItem value="en" onClick={() => handleLangChange("en")}>
                  English
                </MenuItem>
                <MenuItem value="ar" onClick={() => handleLangChange("ar")}>
                  عربي
                </MenuItem>
              </Menu>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <div className="auth_form">
                {email ? (
                  <>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.auth.emailId}
                      </Typography>

                      <TextField
                        hiddenLabel
                        placeholder={translation.auth.emailId}
                        fullWidth
                        name="email"
                        type="email"
                        variant="outlined"
                        className="text_field"
                        id="email"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.auth.password}
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder={translation.auth.password}
                        fullWidth
                        name="password"
                        id="password"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!showPassword)
                                }
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box className="remember_box">
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={formik.values.remember}
                          name="remember"
                          onChange={formik.handleChange}
                          label={translation.auth.remember}
                        />
                      </Box>
                      <Box
                        className="anchor_link"
                        onClick={() => navigate("/forgot-password")}
                      >
                        <Typography style={{ fontWeight: 600 }}>
                          {translation.auth.forgotPassword}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.phone}
                    </Typography>
                    <PhoneInput
                      enableSearch
                      value={formik.values.countryCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translation.auth.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent default behavior (form submission)
                          formik.handleSubmit(); // Manually submit the form
                        }
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}

                <Box sx={{ pt: 2, mb: 3 }}>
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {translation.auth.login}
                  </Button>
                </Box>
                <h4>
                  {translation.auth.letsJoin}
                  <span onClick={() => navigate("/sign-up")}>
                    {translation.auth.signUp}
                  </span>
                </h4>
              </div>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>{translation.auth.helloThere}</h3>
              <p>{translation.auth.joinCommunity}</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/sign-up")}
                >
                  {translation.auth.signUp}
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook2.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        © <p>{moment().year()}</p>
        <p> All Rights Reserved |</p>
        <p>Cooklify |</p>
        <p className="p_links" onClick={() => navigate("/privacy-policy")}>
          Privacy Policy
        </p>
        |
        <p className="p_links" onClick={() => navigate("/terms")}>
          Terms of Use
        </p>
        |<p className="p_links">About us</p>
      </div>
    </div>
  );
};

export default Login;
