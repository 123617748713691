/* eslint-disable react-hooks/exhaustive-deps */
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Fade,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useEditProfileMutation,
  useSendOtpMutation,
  useVerifyPhoneEmailMutation,
} from "../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/mediaUpload";
import moment from "moment";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import OTPInput from "react-otp-input";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import { useSelector } from "react-redux";
import useTranslation from "../../hooks/Translation";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const Profile = () => {
  const translation = useTranslation() as any;
  const token = useSelector(getToken);
  const style = generateResponsiveStyle();
  const user = useAuth();
  const dispatch = useAppDispatch();
  const [updateProfile, { isLoading }] = useEditProfileMutation();

  const [image, setImage] = useState<string>("");
  const [open_gallery, setOpen_gallery] = useState(false);
  const [preview, setPreview] = useState<string>("");
  const [myImages, setMyImages] = useState<any>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [resend, setResend] = useState(false);
  const [countDown, setCountDown] = useState<number>(59);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [type, setType] = useState("");
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const [optVerificationMutation] = useVerifyPhoneEmailMutation();
  const [ForgotPasswordMutation] = useSendOtpMutation();

  const [category, setCategory] = useState<any>("");
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const handleSubmit = async (verifytype: string) => {
    if (otp?.length === 4) {
      setError(false);
      const body = {
        type: "verify",
        key: type === "2" ? formik.values.phone : formik.values.email,
        ...(type === "2" ? { countryCode: formik.values.countryCode } : {}),
        ...(type === "2" ? { countryName: formik.values.countryName } : {}),
        code: otp,
      };

      try {
        const res = await optVerificationMutation(body).unwrap();
        if (res?.statusCode === 200) {
          dispatch(
            setCredentials({
              user: res?.data,
              token: token,
            })
          );
          setOpen(false);
          setResend(false);
          setOtp("");
          if (verifytype === "email") {
            setEmailVerified(true);
          } else {
            setPhoneVerified(true);
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (open) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, open]);

  const sendResentOtp = async () => {
    const body = {
      key: type === "phone" ? formik.values.phone : formik.values.email,
      ...(type === "phone" ? { countryCode: formik.values.countryCode } : {}),
      ...(type === "phone" ? { countryName: formik.values.countryName } : {}),
    };
    try {
      const response = await ForgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        if (resend) {
          showToast(translation.other.resendSend);
        }
        setOpen(true);
        setResend(false);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const sendOtp = async (type: string) => {
    const body = {
      key: type === "phone" ? formik.values.phone : formik.values.email,
      ...(type === "phone" ? { countryCode: formik.values.countryCode } : {}),
      ...(type === "phone" ? { countryName: formik.values.countryName } : {}),
    };
    try {
      const response = await ForgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        setOpen(true);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  //for image

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError(translation.error.imageType1);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      nickName: user?.nickName || "",
      phone: user?.phone || "",
      countryCode: user?.countryCode || "+965",
      countryName: user?.countryName || "KW",
      dob: user?.dob || "",
      bio: user?.bio || "",
      headline: user?.headline || "",
      address: user?.address || "",
      profession: "", // 1 -> chef, 2-> photographer
      accountNumber: user?.accountNumber || "",
      holderName: user?.holderName || "",
      swiftCode: user?.swiftCode || "",
      bankName: user?.bankName || "",
      image: user?.image || "",
      webLink: user?.website || "",
      instaLink: user?.instagram || "",
      facebookLink: user?.facebook || "",
      youtubeLink: user?.youtube || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.error.emailReq)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.error.validEmail
        ),
      firstName: Yup.string()
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),
      lastName: Yup.string()
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),
      nickName: Yup.string()
        .required(translation.error.nicReq)
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),

      phone: Yup.string()
        .required(translation.error.phoneReq)
        .min(6, translation.error.minPhone)
        .max(16, translation.error.maxPhone),
      countryCode: Yup.string().required(translation.error.phoneReq),
      // dob: Yup.string().required(translation.error.dobReq),
      headline: Yup.string()
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),

      bio: Yup.string()
        .min(2, translation.error.min2)
        .max(250, translation.error.max250),

      address: Yup.string()
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),

      accountNumber: Yup.string()
        // .required(translation.error.required)
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),
      holderName: Yup.string()
        // .required(translation.error.required)
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),

      swiftCode: Yup.string()
        // .required(translation.error.required)
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),
      bankName: Yup.string()
        // .required(translation.error.required)
        .min(2, translation.error.min2)
        .max(100, translation.error.max100),

      instaLink: Yup.string().matches(
        new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ),
        translation.error.validUrl
      ),
      facebookLink: Yup.string().matches(
        new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ),
        translation.error.validUrl
      ),

      webLink: Yup.string().matches(
        new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ),
        translation.error.validUrl
      ),
      youtubeLink: Yup.string().matches(
        new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ),
        translation.error.validUrl
      ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setLoading(true);
      let images: string[] = [];
      if (myImages?.length) {
        setLoading(true);

        await Promise.all(
          myImages?.map(async (item: any) => {
            if (typeof item?.file === "string") {
              images?.push(item?.url);
              return;
            } else {
              const res = await UploadMedia(item?.file);
              if (res?.statusCode === 200 && res?.data?.length) {
                images?.push(res?.data);
              }
            }
          })
        );
      }
      setLoading(false);
      // if (!images?.length) {
      //   setError(true);
      //   return;
      // }
      setError(false);
      let body = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        countryCode: values.countryCode,
        countryName: values.countryName,
        dob: values.dob,
        bio: values.bio,
        headline: values.headline,
        address: values.address,
        profession: Number(category), // 1 -> chef, 2-> photographer
        accountNumber: values.accountNumber,
        holderName: values.holderName,
        swiftCode: values.swiftCode,
        bankName: values.bankName,
        image: values.image,
        documents: images,
        isProfileComplete: true,
        facebook: values.facebookLink,
        instagram: values.instaLink,
        youtube: values.youtubeLink,
        website: values.webLink,
        language: getLang === "en" ? 1 : 2,
        nickName: values.nickName,
      };

      if (!emailVerified || user?.email !== formik.values.email) {
        showError(translation.error.emailAddressValid);
        return;
      } else if (!phoneVerified || user?.phone !== formik.values.phone) {
        showError(translation.error.phoneVerify);
        return;
      } else {
        try {
          const response = await updateProfile(body).unwrap();
          if (response?.statusCode === 200) {
            const token = getFromStorage(STORAGE_KEYS.token);
            showToast(translation.error.profileUpdate);
            dispatch(
              setCredentials({
                user: response?.data || null,
                token: token || null,
              })
            );
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  useEffect(() => {
    if (user) {
      setCategory(user?.profession);
      setPhoneVerified(user?.isPhoneVerify);
      setEmailVerified(user?.isEmailVerify);
      if (user?.documents?.length) {
        if (user?.documents?.length && myImages?.length === 0) {
          let newObj = user?.documents?.map((item) => {
            return {
              file: "File" as any,
              url: item as string,
            };
          });

          setMyImages([...myImages, ...newObj]);
        }
      }
    }
  }, [user]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{translation.auth.editProfile}</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translation.auth.image}
                    </Typography>
                    {formik.values.image ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.image}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={() => {
                            formik.setFieldValue("image", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: ".png, .jpg, .jpeg, .svg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.firstName}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.firstName}
                      id="firstName"
                      inputProps={{ maxLength: 100 }}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                          // Handle special cases, if needed
                        } else {
                          if (getLang === "en" && isString(val.target.value)) {
                            formik.handleChange(val);
                          } else {
                            formik.handleChange(val);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.lastName}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      id="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.lastName}
                      className="text_field"
                      inputProps={{ maxLength: 100 }}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.nickName}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="nickName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.nickName}
                      id="nickName"
                      inputProps={{ maxLength: 100 }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.nickName}
                      helperText={
                        formik.touched.nickName && formik.errors.nickName
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translation.auth.email}{" "}
                      <span className="asterisk">
                        {" "}
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <TextField
                        // disabled={user?.isEmailVerify ? true : false}
                        hiddenLabel
                        type={"email"}
                        name="email"
                        variant="outlined"
                        fullWidth
                        placeholder={translation.auth.email}
                        inputProps={{ maxLength: 100 }}
                        id="email"
                        className="text_field"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      {(formik.values.email &&
                        user?.email !== formik.values.email) ||
                      !user?.isEmailVerify ? (
                        <div className="verifydiv">
                          <h4
                            onClick={() => {
                              sendOtp("email");
                              setType("1");
                            }}
                          >
                            {translation.auth.verifyText}
                          </h4>
                        </div>
                      ) : emailVerified &&
                        user?.email === formik.values.email &&
                        user?.isEmailVerify ? (
                        <div className="verified">
                          <VerifiedIcon />
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <div style={{ position: "relative" }}>
                      <Typography className="custom_label">
                        {translation.auth.phone}{" "}
                        <span className="asterisk">
                          {translation.globals.asterisk}
                        </span>
                      </Typography>
                      <PhoneInput
                        enableSearch
                        value={formik.values.countryCode + formik.values.phone}
                        country={"us"}
                        inputClass="phoneInput"
                        inputStyle={{ width: "100%" }}
                        buttonClass="phoneBtn"
                        placeholder={translation.auth.phone}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {(formik.values.phone &&
                        user?.phone !== formik.values.phone) ||
                      !user?.isPhoneVerify ? (
                        <div className="verifydiv1">
                          <h4
                            onClick={() => {
                              sendOtp("phone");
                              setType("2");
                            }}
                          >
                            {translation.auth.verifyText}
                          </h4>
                        </div>
                      ) : phoneVerified &&
                        user?.phone === formik.values.phone ? (
                        <div className="verified1">
                          <VerifiedIcon />
                        </div>
                      ) : null}
                      {formik.touched.phone && formik.errors.phone ? (
                        <h6 className="err_msg">
                          {formik.touched.phone && formik.errors.phone}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.dob}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"date"}
                      name="dob"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.dob}
                      id="dob"
                      className="text_field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                      helperText={formik.touched.dob && formik.errors.dob}
                      inputProps={{
                        max: moment()
                          .subtract(18, "years")
                          .format("YYYY-MM-DD"),
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.headline}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="headline"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.headline}
                      className="text_field"
                      inputProps={{ maxLength: 100 }}
                      id="headline"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.headline}
                      helperText={
                        formik.touched.headline && formik.errors.headline
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.address}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.address}
                      className="text_field"
                      inputProps={{ maxLength: 100 }}
                      id="address"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.selectPro}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          {translation.globals.select}
                        </MenuItem>
                        <MenuItem value={1}>{translation.auth.chef}</MenuItem>
                        <MenuItem value={2}>
                          {translation.auth.photographer}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.profession && formik.errors.profession ? (
                      <h6 className="err_msg">
                        {formik.touched.profession && formik.errors.profession}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.biography}
                    </Typography>
                    <TextField
                      multiline
                      maxRows={3}
                      minRows={1.1}
                      hiddenLabel
                      type={"text"}
                      name="bio"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.biography}
                      className="text_field"
                      inputProps={{ maxLength: 250 }}
                      id="bio"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.bio}
                      helperText={formik.touched.bio && formik.errors.bio}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.webLink}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="webLink"
                      variant="outlined"
                      fullWidth
                      placeholder="https://www.example.com"
                      className="text_field"
                      inputProps={{ maxLength: 250 }}
                      id="webLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.webLink}
                      helperText={
                        formik.touched.webLink && formik.errors.webLink
                      }
                    />
                  </Grid>

                  <Grid item xs={12} className="heading">
                    <Typography className="custom_label" variant="h5">
                      {translation.auth.socialLink}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.instaLink}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="instaLink"
                      variant="outlined"
                      fullWidth
                      placeholder="https://www.example.com"
                      className="text_field"
                      inputProps={{ maxLength: 250 }}
                      id="instaLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.instaLink}
                      helperText={
                        formik.touched.instaLink && formik.errors.instaLink
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.fbLink}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="facebookLink"
                      variant="outlined"
                      fullWidth
                      placeholder="https://www.example.com"
                      className="text_field"
                      inputProps={{ maxLength: 250 }}
                      id="facebookLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.facebookLink}
                      helperText={
                        formik.touched.facebookLink &&
                        formik.errors.facebookLink
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.youtubeLink}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="youtubeLink"
                      variant="outlined"
                      fullWidth
                      placeholder="https://www.example.com"
                      className="text_field"
                      inputProps={{ maxLength: 250 }}
                      id="youtubeLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.youtubeLink}
                      helperText={
                        formik.touched.youtubeLink && formik.errors.youtubeLink
                      }
                    />
                  </Grid>

                  <Grid item xs={12} className="heading">
                    <Typography className="custom_label" variant="h5">
                      {translation.auth.accDetail}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translation.auth.accNo}
                    </Typography>
                    <TextField
                      inputProps={{ maxLength: 20 }}
                      hiddenLabel
                      type={"text"}
                      name="accountNumber"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.accNo}
                      className="text_field"
                      id="accountNumber"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.accountNumber}
                      helperText={
                        formik.touched.accountNumber &&
                        formik.errors.accountNumber
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.accHolderName}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="holderName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.accHolderName}
                      className="text_field"
                      inputProps={{ maxLength: 100 }}
                      id="holderName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.holderName}
                      helperText={
                        formik.touched.holderName && formik.errors.holderName
                      }
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.swiftCode}
                    </Typography>
                    <TextField
                      inputProps={{ maxLength: 20 }}
                      hiddenLabel
                      type={"text"}
                      name="swiftCode"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.swiftCode}
                      className="text_field"
                      id="swiftCode"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.swiftCode}
                      helperText={
                        formik.touched.swiftCode && formik.errors.swiftCode
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.bankName}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="bankName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.auth.bankName}
                      className="text_field"
                      inputProps={{ maxLength: 100 }}
                      id="bankName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.bankName}
                      helperText={
                        formik.touched.bankName && formik.errors.bankName
                      }
                    />
                  </Grid>

                  {myImages?.length ? (
                    <Grid item xs={12} className="heading">
                      <Typography className="custom_label" variant="h5">
                        {translation.auth.uploadCerti}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Grid container spacing={2} className="form_control">
                      {myImages?.length ? (
                        <>
                          {myImages?.map((item: any, i: any) => {
                            return (
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                                className="upload_document w_50"
                                key={i}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOpen_gallery(true);
                                  setPreview(item?.url);
                                }}
                              >
                                <div className="inner">
                                  <figure>
                                    <img src={item?.url} alt="upload" />
                                  </figure>
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    // onClick={() => setError(true)}
                  >
                    {translation.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
      {/* Gallery */}
      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box style={{ maxWidth: "50vw" }}>
              <Button
                className="close_btn"
                onClick={() => {
                  setOpen_gallery(false);
                }}
              >
                <CloseIcon />
              </Button>
              <figure style={{ height: "330px" }}>
                <img
                  src={preview}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </figure>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setResend(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="auth_parnt">
            <div className="auth_left">
              <h2>{translation.auth.verify}</h2>
              <Box sx={{ paddingBottom: 5 }}></Box>
              <FormControl className="opt_fields" sx={{ width: "100%" }}>
                <OTPInput
                  containerStyle={{ width: "70%", margin: "auto" }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={otpStyle}
                  inputType="tel"
                />
                <br />
                {error && otp.length !== 4 ? (
                  <h6 className="err_msg" style={{ textAlign: "center" }}>
                    {translation.error.required}
                  </h6>
                ) : (
                  ""
                )}
              </FormControl>

              <Box sx={{ pt: 3 }}>
                <Button
                  className="btn btn_primary"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => handleSubmit("phone")}
                >
                  {translation.auth.verify}
                </Button>
              </Box>
              {countDown === 0 ? (
                <Box
                  sx={{
                    textAlign: "center",
                    pt: 2,
                  }}
                >
                  <Typography
                    className="anchor_link"
                    sx={{ cursor: "pointer", fontWeight: 600 }}
                    onClick={() => {
                      setResend(true);
                      sendResentOtp();
                    }}
                  >
                    {translation.auth.resendOtp}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      color: "grey",
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {translation.auth.codeExpire}{" "}
                    <p
                      style={{
                        margin: 0,
                        color: "#d63853",
                        paddingLeft: 3,
                        minWidth: 70,
                      }}
                    >
                      {countDown < 10
                        ? `00: 0${countDown}`
                        : `00: ${countDown}`}
                    </p>
                  </Typography>
                </Box>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Profile;
