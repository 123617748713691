import { createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#D63853",
    },
    secondary: {
      main: "#D63853",
    },
    info: {
      main: "#D63853",
    },
    success: {
      main: "#00C9A5",
    },
    error: {
      main: "#E3597A",
    },
    warning: {
      main: "#F9F871",
    },
  },
});
export default theme;
