import React, { useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import useTranslation from "../../hooks/Translation";
import { useLazyGetNotificationQuery } from "../../services/common";
import { showError } from "../../constants";

const RecievedNotifications = () => {
  const translation = useTranslation() as any;
  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];
  const [notificationMutation] = useLazyGetNotificationQuery();
  const handleGetNotification = async () => {
    try {
      const response = await notificationMutation({}).unwrap();
      if (response?.statusCode === 200) {
        // setAllRating(response?.data?.rating || []);
        // setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    handleGetNotification();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{translation.other.recieveNotif}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">{/* <SearchBar /> */}</Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {" "}
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>{translation.orders.userName}</TableCell>
                  <TableCell>{translation.orders.userEmail}</TableCell>
                  <TableCell>{translation.globals.title}</TableCell>
                  <TableCell>{translation.globals.description}</TableCell>

                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.CustomerName}</TableCell>
                    <TableCell>{row.CustomerEmail}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default RecievedNotifications;
