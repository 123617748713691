import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useState } from "react";

const AddCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [category, setCategory] = useState<string>("");

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Category</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setFileName(files[0].name);
                                setImage(URL.createObjectURL(files[0]));
                              } else {
                                setAlertType(0);
                                setShowAlert(true);
                                setAlertMessage(
                                  "This field only accepts images."
                                );
                              }
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  {state === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          className="select_div"
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="cat1">Category 1</MenuItem>
                          <MenuItem value="cat2">Category 2</MenuItem>
                          <MenuItem value="cat3">Category 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {state === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Sub-category Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Sub-category Name"
                      />
                    </Grid>
                  ) : (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Category Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Category Name"
                      />
                    </Grid>
                  )}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
