import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAikK_op9CxjasC2xDmlObvMRr94s-yjb0",
  authDomain: "cooklify.firebaseapp.com",
  projectId: "cooklify",
  storageBucket: "cooklify.appspot.com",
  messagingSenderId: "87223202088",
  appId: "1:87223202088:web:d3fa1fcf858dfcd3d8601d",
  measurementId: "G-84RG4WV639",
};

const app = initializeApp(firebaseConfig);
export default app;
