export const en = {
  globals: {
    save: "Save",
    submit: "Submit",
    back: "Back",
    select: "Select",
    serialNo: "S.No",
    title: "Title",
    description: "Description",
    price: "Price",
    status: "Status",
    actions: "Actions",
    free: "Free",
    no: "No",
    found: "Found",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    view: "View",
    paid: "Paid",
    english: "(English)",
    arabic: "(Arabic)",
    searchHere: "Search here",
    send: "Send",
    lang: "Select Language",
    asterisk: "*",
    language: "Language :",
  },
  auth: {
    emailId: "Email Address",
    emailIdLabel: "Email Address *",
    password: "Password",
    remember: "Remember me",
    forgotPassword: "Forgot Password?",
    oldPass: "Old Password",
    confmPas: "Confirm Password",
    otpVerification: "OTP Verification",
    verify: "Verify Otp",
    verify1: "Verify OTP",
    resendOtp: "Resend Otp",
    login: "Sign In",
    resetPass: "Reset Password",
    editProfile: "Edit Profile",
    firstName: "First Name",
    nickName: "Nick Name",
    nickNameLabel: "Nick Name *",
    lastName: "Last Name",
    email: "Email",
    emailLabel: "Email *",
    phone: "Phone Number",
    phoneLabel: "Phone Number *",
    signInTitle: "Sign In to Cooklify",
    cooklify: "Cooklify",
    usePhone: "Or use your",
    letsJoin: "Let's Join Us.",
    signUp: "Sign Up",
    helloThere: "Hello There, Join Us",
    joinCommunity: "Enter your personal details and join the cooking community",
    welcome: "Welcome Back",
    already:
      "Already signed up, enter your details and start cooking your first meal today",
    profileCredit:
      "Profile created successfully! Please wait until admin approves your document",
    codeExpire: "The verification code will expire in",
    passMatch: "Passwords must match.",
    confirmPasswordReq: "Confirm password is required.",
    passValid:
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. ",
    createAcc: "Create an Account",
    register: "for registration:",
    profile: "Profile",
    image: "Image",
    verifyText: "Verify",
    dob: "Date of Birth",
    headline: "Headline",
    address: "Address",
    selectPro: "Select Profession",
    selectProLabel: "Select Profession *",
    chef: "Chef",
    photographer: "Photographer",
    biography: "Bio",
    webLink: "Website Link",
    socialLink: "Social Media Links",
    instaLink: "Instagram Link",
    fbLink: "Facebook Link",
    youtubeLink: "Youtube Link",
    accDetail: "Account Details",
    accNo: "A/C number",
    accHolderName: "A/C Holder Name",
    swiftCode: "Swift Code",
    bankName: "Bank Name",
    uploadCerti: "Upload Certificates",
    myProfile: "My Profile",
    changePass: "Change Password",
    logout: "Logout",
    logou: "Are you sure, you want to logout?",
    yes: "Yes",
    no: "No",
    currentPass: "Current Password",
    newPass: "New Password",
  },
  dashboard: {
    dashboard: "Dashboard",
    totalCourses: "Total Courses",
    totalEarning: "Total Earnings",
    totalOrders: "Total Orders",
    totalCustomers: "Total Customer",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    freevidos: "Free Videos",
    collection: "Collection",
    users: "Users",
    earning: "Earnings",
    courses: "Courses",
  },
  courses: {
    manage: "Manage",
    add: "Add",
    course: "Course",
    exportCsv: "Export CSV",
    clearFilter: "Clear Filter",
    category: "Category",
    rating: "Rating",
    lowToHigh: "Low to High",
    highToLow: "High to Low",
    priceRange: "Price Range",
    price: "Price",
    type: "Type",
    discount: "Discount",
    discount1: "You cannot set discount more than price",
    discount2: " Please enter a number between 0 and 100.",
    addvdo: "Add Video",
    addMore: "Add More",
    upload: "Upload",
    coverImg: "Cover Image",
    courseUpdate: "Course Updated successfully",
    courseAdded: "Course Added successfully",
    statusUpdated: "Status updated successfully",
    tryAgain: "Please try again",
    showing: "Showing",
    items: "Items",
    courseDetail: "Course Details",
  },
  error: {
    required: "This field is required",
    imageSize: "Please select a video file smaller than",
    imageType: "Please select a supported video file type",
    imageType1: "Please select a valid image file (png or jpeg).",
    imageValidate: "Please select a video shorter than",
    minutes: "minutes",
    validation1: "Please select a valid video",
    maxValidation: "Maximum 80 character are allowed",
    minValidation: "Minimum 3 characters are required",
    noData: "No Data Found",
    titlereq: "Title is required",
    lessThan: "Please select video less than",
    noFaq: "No faq's listing found",
    emailReq: "Email is required",
    validEmail: "Enter a valid email address!",
    passwordReq: "Password is required!",
    passReq: "Password must be at least 6 characters.",
    phoneReq: "Phone number is required",
    minPhone: "Phone number must be at least 6 digits",
    maxPhone: "Phone number must be at least 16 digits",
    invalidImg: "Invalid file type. Please upload only images.",
    max10: "Cannot upload more than 10 images",
    firstReq: "First name is required",
    nicReq: "Nick name is required",
    min2: "Minimum 2 characters are required",
    max100: "Maximum 100 character are allowed",
    max250: "Maximum 250 character are allowed",
    dobReq: "Date of Birth is required",
    headlineReq: "Headline is required",
    chooseProfession: "Choose your Profession",
    validUrl: "Please enter valid Url",
    emailAddressValid: "Please verify your email address",
    phoneVerify: "Please verify your phone number first",
    approveProfile:
      "Profile created successfully! Please wait until admin approves your profile",
    profileUpdate: "Profile Updated Successfully",
    nosame: "New password cannot be same as old password.",
    oldPass: "Old Password is required",
    passChange: "Password changed successfully",
  },
  orders: {
    order: "Orders",
    userName: "User Name",
    userEmail: "User Email",
    courseName: "Course Title",
    courseDes: "Course Description",
    coursePrice: "Course Price",
    date: "Date",
    ratingReview: "Review & Ratings",
    ratings: "Ratings",
    viewdetails: "View Order Detail",
    subTotal: "Sub Total",
    taxAmt: "Tax Amount",
    grandTotal: "Grand Total",
    studentReview: "Student Review",
    yourComment: "Your Comment",
    addComment: "Add Comment",
    typeHere: "Type here",
    userNickName: "User Nick Name",
    planName: "Plan Name",
    expiry: "Expiry",
  },
  videos: {
    manageProVidoes: "Manage Promotional Videos",
    manageVidoes: "Manage Videos",
    manageOrders: "Manage Orders",
    addVideo: "Add Video",
    addProVideos: "Add Promotional Video",
    vdo: "Video",
    promotional: "Promotional",
    uploadVideo: "Upload Videos",
    proUploaded: "Promotional video updated successfully",
    proAdd: "Promotional video created successfully",
    vdoAdd: "Video created successfully",
    vdoUpdated: "Video Updated successfully",
    addedVdo: "Added Videos",
  },
  other: {
    settings: "Settings",
    contact: "Contact Support",
    terms: "Terms and Conditions",
    notification: "Notification",
    account: "Account",
    raiseQry: "Raise Query",
    query: "Query",
    broadCast: "Broadcast notification",
    earning: "Earnings",
    accountStatus: "Account Status",
    querySent: "Query sent to admin successfully",
    faq: "Faqs",
    resendSend: "Otp resend successfully",
    passSet: "Password reset successfully",
    recieveNotif: "Recieved Notifications",
    ratingDes: "Rating & Review Details",
  },
  subscription: {
    planName: "Plan Name",
    planValidity: "Plan Validity",
    yearly: "Yearly",
    quartly: "Quartly",
    monthly: "Monthly",
    buyPlan: "Buy Membership Plans",
    Features: "Features",
    buy: "Buy Plan",
    buy1: "Please buy a membership plan",
    plan1: "Merchants can add Courses and Promotional videos",
    plan2: "Ability to view more free videos than other users",
  },
};
