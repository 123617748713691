import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { useLazyGetCmsListQuery } from "./services/common";
import { useAppDispatch } from "./hooks/store";
import { resetCms } from "./reducers/generalSlice";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";
import LanguageContextProvider from "./context/Language";
import { getMessaging, onMessage } from "firebase/messaging";

function App() {
  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();
    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div>{event?.data?.data?.title}</div>
              <div>{event?.data?.data?.message}</div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  // useEffect(() => {
  //   const messaging = getMessaging();

  //   const subscribeToTopic = async (topic: string) => {
  //     try {
  //       await messaging.subscribeToTopic(topic);
  //       console.log(`Subscribed to topic: ${topic}`);
  //     } catch (error) {
  //       console.error("Error subscribing to topic:", error);
  //     }
  //   };

  //   // Replace 'your-topic' with the actual topic you want to subscribe to
  //   subscribeToTopic("broadcast");
  // }, []);

  const dispatch = useAppDispatch();
  const token = getFromStorage(STORAGE_KEYS.token);

  const [getCmsMethod] = useLazyGetCmsListQuery();

  const getCmsData = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        // dispatch(resetCms({ cms: res?.data?.[0] || [] }));
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getCmsData();
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <LanguageContextProvider>
      <Routing />
    </LanguageContextProvider>
  );
}

export default App;
