import { END_POINTS } from "../constants/url";
import {
  AllCourse,
  AllOrder,
  AllVideo,
  Category,
  CourseBody,
  Subscription,
  VideoBody,
} from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetParams = {
  size: number;
  page: number;
  query: string;
  videoType?: number;
  category?: string;
  ratings?: string;
  price?: string;
};

type getCourseResponse = {
  count: number;
  data: AllCourse[];
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourse: builder.query<
      CommonResponseType & { data: getCourseResponse },
      GetParams
    >({
      query: ({ size, page, query, category, ratings, price }) => ({
        url:
          END_POINTS.course +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query +
          "&category=" +
          category +
          "&ratings=" +
          ratings +
          "&price=" +
          price,
        method: "GET",
      }),
    }),
    addCourse: builder.mutation<
      CommonResponseType & { data: AllCourse },
      CourseBody
    >({
      query: (body) => ({
        url: END_POINTS.course,
        method: "POST",
        body,
      }),
    }),
    getAllCategory: builder.query<
      CommonResponseType & { data: Category[] },
      {}
    >({
      query: () => ({
        url: END_POINTS.categories,
        method: "GET",
      }),
    }),
    getCourseById: builder.query<
      CommonResponseType & { data: { data: AllCourse } },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.course}/${id}`,
        method: "GET",
      }),
    }),
    deleteCourseById: builder.query<
      CommonResponseType & { data: AllCourse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.course}/${id}`,
        method: "DELETE",
      }),
    }),
    updateCourseById: builder.mutation<
      CommonResponseType & { data: AllCourse },
      { id: string; body: CourseBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.course}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCourseStatus: builder.mutation<
      CommonResponseType & { data: AllCourse },
      {
        id: string;
        body: { isBlocked: boolean };
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.course}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //video
    addVideo: builder.mutation<
      CommonResponseType & { data: AllVideo },
      VideoBody
    >({
      query: (body) => ({
        url: END_POINTS.video,
        method: "POST",
        body,
      }),
    }),
    getAllVideos: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          data: AllVideo[];
        };
      },
      GetParams
    >({
      query: ({ size, page, query, videoType }) => ({
        url:
          END_POINTS.video +
          "?page=" +
          page +
          "&videoType=" +
          videoType +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getVideoById: builder.query<
      CommonResponseType & { data: AllVideo },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.video}/${id}`,
        method: "GET",
      }),
    }),
    deleteVideoById: builder.query<
      CommonResponseType & { data: AllVideo },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.video}/${id}`,
        method: "DELETE",
      }),
    }),
    updateVideoById: builder.mutation<
      CommonResponseType & { data: AllVideo },
      { id: string; body: VideoBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.video}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateVideoStatus: builder.mutation<
      CommonResponseType & { data: AllVideo },
      {
        id: string;
        body: { isBlocked: boolean };
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.video}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getAllOrder: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          orders: AllOrder[];
        };
      },
      GetParams
    >({
      query: ({ size, page, query, category }) => ({
        url:
          END_POINTS.getOrder +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query +
          "&category=" +
          category,

        method: "GET",
      }),
    }),
    getSubscription: builder.query<
      CommonResponseType & { data: Subscription[] },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.subscription}`,
        method: "GET",
      }),
    }),
    buySubscription: builder.mutation<
      CommonResponseType & { data: Subscription },
      { subscription: string }
    >({
      query: (body) => ({
        url: END_POINTS.buySubscription,
        method: "POST",
        body,
      }),
    }),

    cancelSubscription: builder.mutation<
      CommonResponseType & { data: Subscription },
      { subscription: string }
    >({
      query: (body) => ({
        url: END_POINTS.cancelSubscription,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllCategoryQuery,
  //course
  useLazyGetAllCourseQuery,
  useAddCourseMutation,
  useLazyGetCourseByIdQuery,
  useLazyDeleteCourseByIdQuery,
  useUpdateCourseByIdMutation,
  useUpdateCourseStatusMutation,
  //video
  useAddVideoMutation,
  useLazyGetAllVideosQuery,
  useLazyDeleteVideoByIdQuery,
  useLazyGetVideoByIdQuery,
  useUpdateVideoByIdMutation,
  useUpdateVideoStatusMutation,
  useLazyGetAllOrderQuery,

  //subscription
  useLazyGetSubscriptionQuery,
  useBuySubscriptionMutation,
  useCancelSubscriptionMutation,
} = mainApi;
