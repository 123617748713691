/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { ChangeEvent, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber, isString } from "../../../utils/validations";
import { UploadMedia, UploadVideo } from "../../../utils/mediaUpload";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../../constants";
import {
  AllCourse,
  Category,
  CourseBody,
  SettingRes,
  VideoType,
} from "../../../types/General";
import {
  useAddCourseMutation,
  useLazyGetAllCategoryQuery,
  useLazyGetCourseByIdQuery,
  useUpdateCourseByIdMutation,
} from "../../../services/main";
import { useLazyGetSettingQuery } from "../../../services/common";

import useTranslation from "../../../hooks/Translation";
import { RWebShare } from "react-web-share";
import { APP_URL } from "../../../constants/url";

interface Item {
  title: string;
  title_ar: string;
  description: string;
  link: string | null;
  videoType: number;
}

const CourseAdd = () => {
  const { id } = useParams();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [addCourse, { isLoading }] = useAddCourseMutation();
  const [allCategory] = useLazyGetAllCategoryQuery();
  const [CourseById] = useLazyGetCourseByIdQuery();
  const translation = useTranslation() as any;
  const [updateCourse, updateCourseMutation] = useUpdateCourseByIdMutation();

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [category, setCategory] = useState<string>("");
  const [priceType, setPriceType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [course, setCourse] = useState<AllCourse | null>(null);
  const [video, setVideo] = useState<string | null>(null);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [videoTitle_ar, setVideoTitle_ar] = useState<string>("");
  const [vdoDescription, setVdoDescription] = useState<string>("");

  const [items, setItems] = useState<any>([]);
  const [videoDuration, setVideoDuration] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<string>("");

  const convertDataUrltoImgUrl = async (base64Image: any) => {
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create an image URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
    // Now, 'imageUrl' contains a regular image URL that you can use in your application
    const response = await UploadMedia(blob);
    if (response?.statusCode === 200) {
      // formik.setFieldValue("coverImg", response?.data);
      setThumbnail(response?.data);
    } else {
      showError(response?.message);
    }
  };

  const [getSettings] = useLazyGetSettingQuery();
  const [setting, setSetting] = useState<SettingRes>();

  const getSettingsApi = async () => {
    try {
      const res = await getSettings({}).unwrap();
      if (res?.statusCode === 200) {
        setSetting(res?.data);
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  useEffect(() => {
    getSettingsApi();
  }, []);

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileSizeLimit = Number(setting?.maxVideoSize) * 1024 * 1024; // 6 MB in bytes
      if (file.size > fileSizeLimit) {
        showError(`${translation.error.imageSize} ${setting?.maxVideoSize} MB`);
        return;
      }
      const isWebMSupported = setting?.isWebMSupported ?? false;
      const isMp4Supported = setting?.isMp4Supported ?? false;
      const isAVISupported = setting?.isAVISupported ?? false;
      // Check if the selected file type is supported
      const allowedTypes = [];
      if (isWebMSupported) {
        allowedTypes.push("video/webm");
      }
      if (isMp4Supported) {
        allowedTypes.push("video/mp4");
      }
      if (isAVISupported) {
        allowedTypes.push("video/x-msvideo");
      }
      if (allowedTypes.length === 0 || !allowedTypes.includes(file.type)) {
        showError(translation.error.imageType);
        return;
      }
      setLoading(true);
      // Create a video element
      const video = document.createElement("video");
      video.preload = "metadata";
      // Set the video source
      video.src = URL.createObjectURL(file);
      // Listen for the loadedmetadata event
      video.addEventListener("loadedmetadata", async () => {
        const duration = video.duration;
        const reqDuration = Number(setting?.maxVideoDuration) * 60;
        console.log(reqDuration);
        if (duration > reqDuration) {
          showError(
            `${translation.error.imageValidate} ${setting?.maxVideoDuration} ${translation.error.minutes}`
          );
          setLoading(false);
          return;
        }
        setVideoDuration(Math.ceil(duration));
        // Set the currentTime to a suitable position for capturing a thumbnail
        video.currentTime = Math.min(1, duration); // Capture thumbnail after 1 second
        // Wait for the seeked event
        video.addEventListener("seeked", async () => {
          // Create a canvas element
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          // Draw the current frame onto the canvas
          const context = canvas.getContext("2d");
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);
          // Convert the canvas to a data URL representing the thumbnail
          const base64Image = canvas.toDataURL("image/jpeg");
          convertDataUrltoImgUrl(base64Image);
          if (file.type.startsWith("video/")) {
            const res = await UploadVideo(file);
            if (res?.statusCode === 200) {
              setVideo(res?.data);
              setLoading(false);
            } else {
              setLoading(false);
              showError(res?.message);
            }
          } else {
            // Display an error message for non-image files
            showError(translation.error.validation1);
          }
          // Clean up: remove the video and canvas elements
          video.remove();
          canvas.remove();
        });
      });
      // Append the video element to the document (necessary for triggering events)
      document.body.appendChild(video);
    }
  };

  const handleAddClick = () => {
    if (!video || !videoTitle || !vdoDescription) {
      setError2(true);
      return;
    }
    if (videoTitle.trim() !== "" && vdoDescription.trim() !== "") {
      const newItem: VideoType = {
        title: videoTitle,
        title_ar: videoTitle_ar,
        description: vdoDescription,
        link: video,
        videoType: 1,
        duration: videoDuration,
        thumbnail: thumbnail,
      };

      setItems([newItem, ...items]);

      // Clear the form fields after adding the item
      setVideo("");
      setVideoTitle("");
      setVideoTitle_ar("");
      setVdoDescription("");
      setError2(false);
    }
  };

  const handleDeleteClick = (index: any) => {
    // const updatedItems = items.filter((item: any) => item._id !== _id);
    // setItems(updatedItems);

    // const updatedItems = items.filter((item: any) => item.id !== id);
    const updatedItems = [...items];

    // Remove the item at the specified index
    updatedItems.splice(index, 1);

    // Update the state with the modified array
    setItems(updatedItems);
  };

  const navigate = useNavigate();

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handlePricingChange = (event: SelectChangeEvent) => {
    setPriceType(event.target.value as string);
  };

  //for image
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("coverImg", res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError(translation.error.imageType);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseTitle: course?.title || "",
      courseTitle_ar: course?.title_ar || "",
      price: course?.price || "",
      courseDes: course?.description || "",
      coverImg: course?.coverImage || "",
      discount: course?.discount || "",
    },
    validationSchema: Yup.object({
      courseTitle: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      courseTitle_ar: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      courseDes: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),

      price:
        priceType === "1"
          ? Yup.string().notRequired()
          : Yup.string()
              .required(translation.error.required)
              .min(2, translation.error.minValidation),
      coverImg: Yup.string().required(translation.error.required),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!category) {
        return true;
      }
      if (
        Number(values?.discount) >= Number(values?.price) &&
        priceType === "2"
      ) {
        return true;
      }

      const body = {
        title: values?.courseTitle,
        title_ar: values?.courseTitle_ar,
        description: values?.courseDes,
        coverImage: values?.coverImg,
        price: priceType === "1" ? 0 : Number(values?.price),
        category: category,
        pricingType: Number(priceType),
        videos: items,
        discount: priceType === "1" ? 0 : Number(values?.discount),
        language: getLang === "en" ? 1 : 2,
      };
      console.log(body, "bodybody");

      if (id && id !== "add") {
        try {
          const response = await updateCourse({
            id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.courses.courseUpdate);
            formik.resetForm();
            navigate("/manage-courses");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addCourse(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.courses.courseAdded);
            formik.resetForm();
            navigate("/manage-courses");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getCourseById = async (id: string) => {
    try {
      const response = await CourseById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setCourse(response?.data?.data);
        setCategory(response?.data?.data?.category?._id);
        setPriceType(String(response?.data?.data?.pricingType));
        setItems(response?.data?.data?.videos || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);

  useEffect(() => {
    if (id && id !== "add") {
      getCourseById(id);
    }
  }, []);

  return (
    <>
      <Loader
        isLoad={isLoading || loading || updateCourseMutation?.isLoading}
      />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {id
                ? `${translation.globals.edit} ${translation.courses.course}`
                : `${translation.globals.add} ${translation.courses.course}`}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-courses");
              }}
            >
              {translation.globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.course} {translation.globals.title}{" "}
                      {translation.globals.english}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="courseTitle"
                      variant="outlined"
                      fullWidth
                      placeholder={`${translation.courses.course} ${translation.globals.title}`}
                      id="courseTitle"
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseTitle}
                      helperText={
                        formik.touched.courseTitle && formik.errors.courseTitle
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.course} {translation.globals.title}{" "}
                      {translation.globals.arabic}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="courseTitle_ar"
                      variant="outlined"
                      fullWidth
                      placeholder={`${translation.courses.course} ${translation.globals.title}`}
                      id="courseTitle_ar"
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseTitle_ar}
                      helperText={
                        formik.touched.courseTitle_ar &&
                        formik.errors.courseTitle_ar
                      }
                    />
                  </Grid>

                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.globals.select}{" "}
                      {translation.courses.category}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          {translation.globals.select}
                        </MenuItem>
                        {categories?.length
                          ? categories?.map((item) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">
                          {translation.error.required}
                        </h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.price} {translation.courses.type}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={priceType}
                        value={priceType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handlePricingChange}
                      >
                        <MenuItem value="" disabled>
                          {translation.globals.select}
                        </MenuItem>
                        <MenuItem value="1">
                          {translation.globals.free}
                        </MenuItem>
                        <MenuItem value="2">
                          {translation.globals.paid}
                        </MenuItem>
                      </Select>
                      {error && !priceType ? (
                        <h6 className="err_msg">
                          {translation.error.required}
                        </h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.price}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="price"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.courses.price}
                      inputProps={{ maxLength: 10 }}
                      disabled={priceType === "1" ? true : false}
                      id="price"
                      className="text_field"
                      onChange={(event) => {
                        const inputValue = event.target.value;

                        // Validate input: allow digits, up to one decimal point, and ensure no more than two decimal places
                        const regex = /^\d*\.?\d{0,2}$/;
                        if (regex.test(inputValue)) {
                          formik.handleChange(event);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={priceType === "1" ? 0 : formik.values.price}
                      helperText={formik.touched.price && formik.errors.price}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      {translation.courses.discount}(%)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.courses.discount}
                      inputProps={{ maxLength: 3 }}
                      disabled={priceType === "1" ? true : false}
                      id="discount"
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "." ||
                          val.target.value === "0"
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={priceType === "1" ? 0 : formik.values.discount}
                      helperText={
                        formik.touched.discount && formik.errors.discount
                      }
                    />

                    {error &&
                    priceType === "2" &&
                    Number(formik.values.discount) >=
                      Number(formik.values.price) ? (
                      <h6 className="err_msg">
                        {translation.courses.discount1}
                      </h6>
                    ) : (
                      ""
                    )}
                    {error &&
                    priceType === "2" &&
                    Number(formik.values.discount) > 100 ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.courses.discount2}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={6} xs={12} /> */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.globals.description}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder={translation.globals.description}
                      fullWidth
                      multiline
                      minRows={6}
                      maxRows={6}
                      name="courseDes"
                      variant="outlined"
                      id="courseDes"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseDes}
                      helperText={
                        formik.touched.courseDes && formik.errors.courseDes
                      }
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.coverImg}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    {formik.values.coverImg ? (
                      <div
                        className="upload_image_preview2"
                        style={{ height: "158px" }}
                      >
                        <CardMedia
                          component="img"
                          image={formik.values.coverImg}
                          alt="photo"
                        />
                        <div className="shareIcon">
                          <CancelIcon
                            onClick={() => {
                              formik.setFieldValue("coverImg", "");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Box
                        className="upload_image_bnr"
                        style={{ height: "158px" }}
                      >
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !formik.values.coverImg ? (
                      <h6 className="err_msg">{translation.error.required}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <h1
                  className="mn_hdng"
                  style={{ fontSize: "20px", marginTop: 30 }}
                >
                  {translation.courses.addvdo}
                </h1>

                <div className="card_main">
                  {items &&
                    items.map((item: any, i: number) => (
                      <div key={item?._id} className="card">
                        <div className="shareIcon" style={{ padding: "14px" }}>
                          {id ? (
                            <RWebShare
                              data={{
                                text: "Share",
                                url: APP_URL,
                                title: "Share Course",
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <ShareIcon />
                            </RWebShare>
                          ) : null}
                          <DeleteIcon
                            style={{ color: "red" }}
                            onClick={() => handleDeleteClick(i)}
                          />
                        </div>

                        {item?.link && (
                          <figure>
                            <video controls width="100%" height="100%">
                              <source src={item?.link} type="video/mp4" />
                            </video>
                          </figure>
                        )}
                        <div className="desc">
                          <h4>
                            {getLang === "en" ? item?.title : item?.title_ar}
                          </h4>
                          <p>{item?.description || ""}</p>
                          {/* <p className="time">Oct 22, 2023</p> */}
                        </div>
                      </div>
                    ))}
                </div>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.courses.upload}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>

                    {video ? (
                      <div className="upload_image_preview2">
                        {/* <CardMedia component="img" ={video} alt="photo" /> */}
                        <video controls width="100%" height="100%">
                          <source src={video} type="video/mp4" />
                        </video>
                        <div className="shareIcon">
                          <CancelIcon onClick={() => setVideo("")} />
                        </div>
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-vdo">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-vdo"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={handleVideoUpload}
                            onClick={(
                              event: React.MouseEvent<HTMLInputElement>
                            ) => {
                              const inputElement =
                                event.target as HTMLInputElement;
                              inputElement.value = "";
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}

                    {error2 && !video ? (
                      <h6 className="err_msg">{translation.error.required}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.globals.title} {translation.globals.english}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="courseName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.globals.title}
                      inputProps={{ maxLength: 30 }}
                      value={videoTitle}
                      onChange={(e) => setVideoTitle(e.target.value)}
                    />
                    {error2 && !videoTitle ? (
                      <h6 className="err_msg">{translation.error.required}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.globals.title} {translation.globals.arabic}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="courseName"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.globals.title}
                      inputProps={{ maxLength: 30 }}
                      value={videoTitle_ar}
                      onChange={(e) => setVideoTitle_ar(e.target.value)}
                    />
                    {error2 && !videoTitle_ar ? (
                      <h6 className="err_msg">{translation.error.required}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.globals.description}{" "}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder={translation.globals.description}
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      inputProps={{ maxLength: 100 }}
                      name="description"
                      variant="outlined"
                      id="description"
                      value={vdoDescription}
                      onChange={(e) => setVdoDescription(e.target.value)}
                    />
                    {error2 && !vdoDescription ? (
                      <h6 className="err_msg">{translation.error.required}</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                {/* <Grid item lg={8} md={8} sm={8} xs={8} /> */}

                {items?.length >= 30 ? null : (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      paddingTop: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      className="btn-like-div"
                      onClick={handleAddClick}
                      color="primary"
                    >
                      {!items?.length
                        ? translation.courses.addMore
                        : translation.courses.addMore}
                    </div>
                  </Grid>
                )}

                <div className="form_btn">
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                    disabled={!items?.length}
                  >
                    {translation.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default CourseAdd;
