/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getCms, resetCms } from "../../reducers/generalSlice";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAccountStatusMutation,
  useLazyGetCmsListQuery,
  useRaiseQueryMutation,
  useUpdateNotificationMutation,
} from "../../services/common";
import {
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const user = useAuth();
  const [updateNotification, { isLoading }] = useUpdateNotificationMutation();
  const [accountStatusMutation] = useAccountStatusMutation();
  const [raiseQuery, raiseQueryMutation] = useRaiseQueryMutation();

  const [value, setValue] = useState(0);
  const [broadcast, setBroadCast] = useState<boolean>(
    user?.isBroadcastNotificationEnabled || false
  );
  const [emailNotif, setEmailNotif] = useState<boolean>(
    user?.isEmailEnabled || false
  );
  const [ratingNotif, setRatingNotif] = useState<boolean>(
    user?.isRatingEnabled || false
  );
  const [earningNotif, setEarningNotif] = useState<boolean>(
    user?.isEarningEnabled || false
  );
  const [accountStatus, setAccountStatus] = useState<boolean>(
    user?.isActive || false
  );
  const dispatch = useAppDispatch();
  const [getCmsMethod] = useLazyGetCmsListQuery();

  const getCmsDataApi = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        // dispatch(resetCms({ cms: res?.data?.[0] || [] }));
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDataApi();
  }, []);

  const formik = useFormik({
    initialValues: {
      query: "",
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      query:
        value === 4
          ? Yup.string()
              .required(translation.error.required)
              .max(80, translation.error.maxValidation)
              .min(3, translation.error.minValidation)
          : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      try {
        const response = await raiseQuery({
          query: values?.query,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(translation.other.querySent);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      formik.resetForm();
    },
  });

  const handleNotification = async () => {
    const body = {
      isBroadcastNotificationEnabled: broadcast,
      isEmailEnabled: emailNotif,
      isRatingEnabled: ratingNotif,
      isEarningEnabled: earningNotif,
    };
    try {
      const response = await updateNotification(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (user) {
      setBroadCast(user?.isBroadcastNotificationEnabled);
      setEmailNotif(user?.isEmailEnabled);
      setRatingNotif(user?.isRatingEnabled);
      setEarningNotif(user?.isEarningEnabled);
      setAccountStatus(user?.isActive);
    }
  }, [user]);

  const handleAccountStatus = async (status: boolean) => {
    try {
      const response = await accountStatusMutation({
        isActive: status,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translation.courses.statusUpdated);
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getCmsData = useAppSelector(getCms);
  const cms = getCmsData?.[0];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.other.settings}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translation.other.contact} {...a11yProps(0)} />
              <Tab label={translation.other.terms} {...a11yProps(1)} />
              <Tab label={translation.other.notification} {...a11yProps(2)} />
              <Tab label={translation.other.account} {...a11yProps(3)} />
              <Tab label={translation.other.raiseQry} {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.email}
                    </Typography>
                    <Typography component="p">
                      <Link to={`mailto:${cms?.email || ""}`}>
                        {cms?.email || ""}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation.auth.phone}
                    </Typography>

                    <Typography component="p">
                      <Link to={`tel:${cms?.phone || ""}`}>
                        {cms?.countryCode
                          ? (cms?.countryCode.includes("+") ? "" : "+") +
                            cms?.countryCode
                          : null}{" "}
                        {cms?.phone || "--"}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Card
                sx={{ p: 3 }}
                className={cms ? "policy_txt" : "policy_txt2"}
                dangerouslySetInnerHTML={{
                  __html: cms
                    ? getLang === "en"
                      ? cms?.termsAndConditions
                      : cms?.termsAndConditions_ar
                    : translation.error.noData,
                }}
              ></Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Card className="notifi">
                <div className="notifi-li">
                  <div className="flexDiv">
                    <Typography className="custom_label">
                      {translation.other.broadCast}
                    </Typography>
                    <Switch
                      {...label}
                      checked={broadcast}
                      size="small"
                      onChange={() => setBroadCast(!broadcast)}
                    />
                  </div>
                  <div className="flexDiv">
                    <Typography className="custom_label">
                      {" "}
                      {translation.auth.email}
                    </Typography>
                    <Switch
                      {...label}
                      checked={emailNotif}
                      size="small"
                      onChange={() => setEmailNotif(!emailNotif)}
                    />
                  </div>
                  <div className="flexDiv">
                    <Typography className="custom_label">
                      {" "}
                      {translation.orders.ratings}
                    </Typography>
                    <Switch
                      {...label}
                      checked={ratingNotif}
                      size="small"
                      onChange={() => setRatingNotif(!ratingNotif)}
                    />
                  </div>
                  <div className="flexDiv">
                    <Typography className="custom_label">
                      {translation.other.earning}
                    </Typography>
                    <Switch
                      {...label}
                      checked={earningNotif}
                      size="small"
                      onChange={() => setEarningNotif(!earningNotif)}
                    />
                  </div>
                </div>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={handleNotification}
                  >
                    {translation.globals.submit}
                  </Button>
                </div>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Card className="notifi">
                <div className="flexDiv">
                  <Typography className="custom_label">
                    <PersonOutlineOutlinedIcon />{" "}
                    <span>{translation.other.accountStatus}</span>
                  </Typography>
                  <Switch
                    {...label}
                    checked={accountStatus}
                    size="small"
                    onChange={() => setAccountStatus(!accountStatus)}
                  />
                </div>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => handleAccountStatus(accountStatus)}
                  >
                    {translation.globals.submit}
                  </Button>
                </div>
              </Card>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Card sx={{ p: 3 }}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translation.globals.add} {translation.other.query}
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        name="query"
                        variant="outlined"
                        fullWidth
                        placeholder={translation.other.query}
                        multiline
                        inputProps={{ maxLength: 800 }}
                        rows={5}
                        id="query"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.query}
                        helperText={formik.touched.query && formik.errors.query}
                      />
                    </Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translation.globals.submit}
                    </Button>
                  </div>
                </form>
              </Card>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
