import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyDeleteVideoByIdQuery,
  useLazyGetAllVideosQuery,
  useUpdateVideoStatusMutation,
} from "../../services/main";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { AllVideo } from "../../types/General";
import { BuyMembershipModal, Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
import useAuth from "../../hooks/useAuth";
import useSetting from "../../hooks/useSetting";

const ManagePromotion = () => {
  const user = useAuth();
  const setting = useSetting();
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const navigate = useNavigate();
  const [getVideos, { isLoading }] = useLazyGetAllVideosQuery();
  const [deleteById] = useLazyDeleteVideoByIdQuery();
  const [updateStatusMutation] = useUpdateVideoStatusMutation();

  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [videoRes, setVideosRes] = useState<AllVideo[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [openMembershipModal, setOpenMembershipModal] = useState(false);

  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllVideos = async () => {
    try {
      const response = await getVideos({
        size: 10,
        page: page,
        videoType: 3,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setVideosRes(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setVideosRes([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, status: boolean) => {
    const response = await updateStatusMutation({
      id,
      body: {
        isBlocked: status,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = videoRes.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setVideosRes(updatedData);
      showToast(translation.courses.statusUpdated);
    } else {
      showError(translation.courses.tryAgain);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteById({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getAllVideos();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllVideos();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.videos.manageProVidoes}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() =>
                  user?.isSubscriptionRequired ||
                  setting?.isMerchantSubscriptionEnabled
                    ? user?.isSubscriptionActive
                      ? navigate("/manage-promotion/add")
                      : setOpenMembershipModal(true)
                    : navigate("/manage-promotion/add")
                }
              >
                {translation.videos.addVideo}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>{translation.globals.title}</TableCell>
                  <TableCell>{translation.globals.description}</TableCell>
                  <TableCell>{translation.globals.status}</TableCell>
                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videoRes?.length ? (
                  videoRes.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {getLang === "en"
                          ? row?.title || "-"
                          : row?.title_ar || "-"}
                      </TableCell>
                      <TableCell>
                        {row?.description?.length >= 16
                          ? row?.description?.slice(0, 16) + "..."
                          : row?.description || "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title={translation.globals.edit}>
                            <IconButton
                              onClick={() =>
                                user?.isSubscriptionRequired ||
                                setting?.isMerchantSubscriptionEnabled
                                  ? user?.isSubscriptionActive
                                    ? navigate(
                                        `/manage-promotion/edit/${row?._id}`
                                      )
                                    : setOpenMembershipModal(true)
                                  : navigate(
                                      `/manage-promotion/edit/${row?._id}`
                                    )
                              }
                              // onClick={() =>
                              //   navigate(`/manage-promotion/edit/${row?._id}`)
                              // }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={translation.globals.delete}>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      {translation.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="video"
      />
      <Pagination
        setPage={setPage}
        module={videoRes}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <BuyMembershipModal
        setOpenMembershipModal={setOpenMembershipModal}
        openMembershipModal={openMembershipModal}
      />
    </MainContainer>
  );
};

export default ManagePromotion;
