import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import {
  useBuySubscriptionMutation,
  useCancelSubscriptionMutation,
} from "../../services/main";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

type props = {
  subscription: Subscription | null;
};

const Food = ({ subscription }: props) => {
  const user = useAuth();
  console.log(user);
  const navigate = useNavigate();
  const translation = useTranslation();
  const [planType, setPlanType] = useState<string>("");
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [buySubscriptionMutation, { isLoading }] = useBuySubscriptionMutation();
  const [cancelSubscriptionMutation] = useCancelSubscriptionMutation();
  const [userSubscription, setUserSubscription] = useState(user?.subscription);

  const features = [
    {
      id: 1,
      title: translation.subscription.plan1,
    },
  ];

  const filteredFeatures = features.filter((feature) =>
    subscription?.features?.includes(feature.id)
  );

  const createdAtTimestamp = subscription
    ? new Date(subscription?.assignedSubscription?.createdAt).getTime()
    : 0;
  const currentTimestamp = new Date().getTime();
  const timeDifferenceInMilliseconds = currentTimestamp - createdAtTimestamp;

  // Check if the subscription was created within the last 24 hours
  const showCancelPlanButton =
    subscription && timeDifferenceInMilliseconds < 24 * 60 * 60 * 1000;

  const buySubscription = async () => {
    if (subscription) {
      try {
        const response = await buySubscriptionMutation({
          subscription: subscription?._id,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast("Subscription purchased successfully");
          navigate("/buy-membership-plan");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    }
  };

  const cancelSubscription = async () => {
    if (subscription) {
      try {
        const response = await cancelSubscriptionMutation({
          subscription: subscription?._id,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast("Subscription cancelled successfully");
          navigate("/buy-membership-plan");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    // Update the userSubscription state when the user prop changes
    setUserSubscription(user?.subscription);
  }, [user?.subscription]);

  return (
    <CardContent sx={{ p: 1 }}>
      <Loader isLoad={isLoading} />
      <Grid container spacing={2} className="view_box">
        <Grid item lg={10} md={10} sm={10} xs={12} className="view_box_list">
          <Grid container spacing={3} className="mn_box">
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box>
                <Typography component="h5">
                  {translation.subscription.planName}
                </Typography>
                <Typography component="p">
                  {getLang === "en"
                    ? subscription?.name || "-"
                    : subscription?.name_ar || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box>
                <Typography component="h5">
                  {translation.globals.price}
                </Typography>
                <Typography component="p">
                  $ {subscription?.price || "0"}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box>
                <Typography component="h5">
                  {translation.subscription.planValidity}
                </Typography>
                <Typography component="p">
                  {subscription?.validity === 1
                    ? translation.subscription.yearly
                    : subscription?.validity === 2
                    ? translation.subscription.quartly
                    : translation.subscription.monthly}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography component="h5">
                  {translation.globals.description}
                </Typography>
                <Typography component="p">
                  {getLang === "en"
                    ? subscription?.description || "-"
                    : subscription?.description_ar || "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography component="h5">
                  {translation.subscription.Features}
                </Typography>

                <ul>
                  {filteredFeatures?.map((item, i) => (
                    <li key={item?.id}>
                      <Typography component="p">{item?.title || ""}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: "10px" }}>
                {user?.subscription && showCancelPlanButton ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => cancelSubscription()}
                  >
                    Cancel Plan
                  </Button>
                ) : (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => buySubscription()}
                  >
                    Buy Plan
                  </Button>
                )}

                {/* <Button size="large" type="submit" className="btn btn_primary">
                  Get Free Trial
                </Button> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default Food;
