import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../../constants";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials, temporaryToken } from "../../../reducers/authSlice";
import useTranslation from "../../../hooks/Translation";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const translation = useTranslation() as any;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { state } = location;
  console.log("state: ", state);
  const fcm_token = getFromStorage(STORAGE_KEYS.fcmToken);
  const [optVerificationMutation] = usePostVerifyOtpMutation();
  const [ForgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        type: state?.page === "signUp" ? "signup" : "login",
        key: state?.key,
        ...(state?.type === "phone" ? { countryCode: state?.countryCode } : {}),
        ...(state?.type === "phone" ? { countryName: state?.countryName } : {}),
        ...(state?.page === "login" ? { deviceToken: fcm_token } : {}),
        ...(state?.page === "login" ? { deviceType: "WEB" } : {}),
        code: otp,
      };

      try {
        const res = await optVerificationMutation(body).unwrap();
        if (res?.statusCode === 200) {
          if (res?.statusCode === 200) {
            if (state?.page === "signUp") {
              setToStorage(
                STORAGE_KEYS.tempToken,
                JSON.stringify(res?.data?.token)
              );
              dispatch(
                temporaryToken({
                  tempToken: res?.data?.token || null,
                })
              );
              navigate("/profile-setup", { state: state, replace: true });
            } else if (state?.type === "profile") {
              showToast(translation.auth.profileCredit);
              navigate("/", { replace: true });
            } else if (state?.page === "forgot") {
              setToStorage(
                STORAGE_KEYS.tempToken,
                JSON.stringify(res?.data?.token)
              );
              dispatch(
                temporaryToken({
                  tempToken: res?.data?.token || null,
                })
              );
              navigate("/reset-password", { replace: true });
            } else if (state?.page === "login") {
              console.log("state: /kkkkk", state);
              setToStorage(STORAGE_KEYS.token, res?.data?.token || "");
              dispatch(
                setCredentials({
                  user: res?.data,
                  token: res?.data?.token || "",
                })
              );
              if (
                state?.type === "phone" &&
                // res?.data?.isVerified &&
                res?.data?.isProfileComplete
              ) {
                navigate("/dashboard", { replace: true });
              }
              if (res?.data && !res?.data?.isProfileComplete) {
                navigate("/profile-setup", { state: state, replace: true });
              }
            } else {
              showToast("Login Successfully.");
              setToStorage(STORAGE_KEYS.token, res?.data?.token || "");
              setToStorage(STORAGE_KEYS.credentials, JSON.stringify(res?.data));
              dispatch(
                setCredentials({
                  user: res?.data,
                  token: res?.data?.token || "",
                })
              );
              navigate("/dashboard", { replace: true });
            }
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let body = {
      key: state?.key,
    };
    try {
      const response = await ForgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(translation.auth.resendSend);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure className="authLogo">
              <img src="/static/images/logo.png" alt="" />
            </figure>
            <h2>{translation.auth.verify1}</h2>
            <Box sx={{ paddingBottom: 5 }}></Box>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                containerStyle={{ width: "70%", margin: "auto" }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
              <br />
              {error && otp.length !== 4 ? (
                <h6 className="err_msg" style={{ textAlign: "center" }}>
                  {translation.error.required}
                </h6>
              ) : (
                ""
              )}
            </FormControl>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                {translation.auth.verify}
              </Button>
            </Box>
            {countDown === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  pt: 2,
                }}
              >
                <Typography
                  className="anchor_link"
                  sx={{ cursor: "pointer", fontWeight: 600 }}
                  onClick={handleResendOtp}
                >
                  {translation.auth.resendOtp}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {translation.auth.codeExpire}{" "}
                  <p
                    style={{
                      margin: 0,
                      color: "#d63853",
                      paddingLeft: 3,
                      minWidth: 70,
                    }}
                  >
                    {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}
                  </p>
                </Typography>
              </Box>
            )}
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3> {translation.auth.welcome}</h3>
              <p>{translation.auth.already}</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  {translation.auth.login}
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook2.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
