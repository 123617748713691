export const ar = {
  globals: {
    save: "يحفظ",
    submit: "يُقدِّم",
    back: "خلف",
    select: "يختار",
    serialNo: "رقم S",
    title: "عنوان",
    description: "وصف",
    price: "سعر",
    status: "حالة",
    actions: "أجراءات",
    free: "حر",
    no: "لا",
    found: "وجد",
    edit: "يحرر",
    delete: "يمسح",
    add: "يضيف",
    view: "منظر",
    paid: "مدفوع",
    english: "(English)",
    arabic: "(Arabic)",
    searchHere: "ابحث هنا",
    send: "يرسل",
    lang: "اختار اللغة",
    asterisk: "*",
    language: "لغة :",
  },
  auth: {
    emailId: "بريد إلكتروني",
    emailIdLabel: "بريد إلكتروني *",
    password: "كلمة المرور",
    remember: "تذكرنى",
    forgotPassword: "هل نسيت كلمة السر؟",
    oldPass: "كلمة المرور القديمة",
    confmPas: "تأكيد كلمة المرور",
    otpVerification: "التحقق من كلمة المرور لمرة واحدة",
    verify: "التحقق من كلمة المرور لمرة واحدة",
    verify1: "التحقق من كلمة المرور لمرة واحدة",
    resendOtp: "تمت إعادة إرسال Otp بنجاح",
    login: "تسجيل الدخول",
    resetPass: "إعادة تعيين كلمة المرور",
    editProfile: "تعديل الملف الشخصي",
    firstName: "الاسم الأول",
    nickName: "كنية",
    nickNameLabel: "كنية *",
    lastName: "اسم العائلة",
    email: "بريد إلكتروني",
    emailLabel: "بريد إلكتروني *",
    phone: "رقم التليفون",
    phoneLabel: "رقم التليفون *",
    signInTitle: "قم بتسجيل الدخول إلى Cooklify",
    cooklify: "كوكليفي",
    usePhone: "أو استخدم الخاص بك",
    letsJoin: "انضم الينا.",
    signUp: "اشتراك",
    helloThere: "مرحبًا، انضم إلينا",
    joinCommunity: "أدخل بياناتك الشخصية وانضم إلى مجتمع الطبخ",
    welcome: "مرحبًا بعودتك",
    already:
      "قمت بالتسجيل بالفعل، أدخل التفاصيل الخاصة بك وابدأ في طهي وجبتك الأولى اليوم",
    profileCredit:
      "تم إنشاء الملف الشخصي بنجاح! يرجى الانتظار حتى يوافق المسؤول على المستند الخاص بك",
    codeExpire: "ستنتهي صلاحية رمز التحقق في",
    passMatch: "يجب أن تتطابق كلمات المرور.",
    confirmPasswordReq: "تأكيد كلمة المرور مطلوب.",
    passValid:
      "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل، حرف واحد كبير وحرف صغير ورقم واحد وحرف خاص واحد.",
    createAcc: "إنشاء حساب",
    register: "للتسجيل:",
    profile: "حساب تعريفي",
    image: "صورة",
    verifyText: "يؤكد",
    dob: "تاريخ الميلاد",
    headline: "العنوان",
    address: "عنوان",
    selectPro: "اختر المهنة",
    selectProLabel: "اختر المهنة *",
    chef: "طاه",
    photographer: "مصور فوتوغرافي",
    biography: "السيرة الذاتية",
    webLink: "رابط الموقع",
    socialLink: "روابط وسائل التواصل الاجتماعي",
    instaLink: "رابط الانستقرام",
    fbLink: "رابط الفيسبوك",
    youtubeLink: "رابط يوتيوب",
    accDetail: "تفاصيل الحساب",
    accNo: "رقم مكيف الهواء",
    accHolderName: "اسم حامل مكيف الهواء",
    swiftCode: "رمز السرعة",
    bankName: "اسم البنك",
    uploadCerti: "تحميل الشهادات",
    myProfile: "ملفي",
    changePass: "تغيير كلمة المرور",
    logout: "تسجيل خروج",
    logou: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    yes: "نعم",
    no: "لا",
    currentPass: "كلمة السر الحالية",
    newPass: "كلمة المرور الجديدة",
  },
  dashboard: {
    dashboard: "لوحة القيادة",
    totalCourses: "إجمالي الدورات",
    totalEarning: "الأرباح الكلية",
    totalOrders: "إجمالي الطلبات",
    totalCustomers: "إجمالي العملاء",
    daily: "يوميًا",
    weekly: "أسبوعي",
    monthly: "شهريا",
    yearly: "سنوي",
    freevidos: "فيديوهات مجانية",
    collection: "مجموعة",
    users: "المستخدمين",
    earning: "الأرباح",
    courses: "الدورات",
  },
  courses: {
    manage: "يدير",
    add: "يضيف",
    course: "دورة",
    exportCsv: "تصدير CSV",
    clearFilter: "مرشح واضح",
    category: "فئة",
    rating: "تقييم",
    lowToHigh: "من أسفل إلى أعلى",
    highToLow: "من الأعلى إلى الأقل",
    priceRange: "نطاق السعر",
    price: "سعر",
    type: "يكتب",
    discount: "تخفيض",
    discount1: "لا يمكنك تعيين خصم أكثر من السعر",
    discount2: "الرجاء إدخال رقم بين 0 و 100.",
    addvdo: "أضف فيديو",
    addMore: "أضف المزيد",
    upload: "رفع",
    coverImg: "صورة الغلاف",
    courseUpdate: "تم تحديث الدورة بنجاح",
    courseAdded: "تم تحديث الدورة بنجاح",
    statusUpdated: "تم تحديث الحالة بنجاح",
    tryAgain: "حاول مرة اخرى",
    showing: "عرض",
    items: "أغراض",
    courseDetail: "تفاصيل الدورة",
  },
  error: {
    required: "هذه الخانة مطلوبه",
    imageSize: "الرجاء تحديد ملف فيديو أصغر من",
    imageType: "الرجاء تحديد نوع ملف فيديو مدعوم",
    imageType1: "الرجاء تحديد ملف صورة صالح (png أو jpeg).",
    imageValidate: "الرجاء تحديد فيديو أقصر من",
    minutes: "دقائق",
    validation1: "الرجاء تحديد فيديو صالح",
    maxValidation: "الحد الأقصى المسموح به هو 80 حرفًا",
    minValidation: "مطلوب 3 أحرف على الأقل",
    noData: "لم يتم العثور على نتائج",
    titlereq: "العنوان مطلوب",
    lessThan: "يرجى تحديد فيديو أقل من",
    noFaq: "لم يتم العثور على قائمة الأسئلة الشائعة",
    emailReq: "البريد الالكتروني مطلوب!",
    validEmail: "أدخل عنوان بريد إلكتروني صالح!",
    passwordReq: "كلمة المرور مطلوبة!",
    passReq: "كلمة المرور مطلوبة!",
    phoneReq: "رقم الهاتف مطلوب",
    minPhone: "يجب أن يتكون رقم الهاتف من 6 أرقام على الأقل",
    maxPhone: "يجب أن يتكون رقم الهاتف من 16 رقمًا على الأقل",
    invalidImg: "نوع الملف غير صالح. يرجى تحميل الصور فقط.",
    max10: "لا يمكن تحميل أكثر من 10 صور",
    firstReq: "الإسم الأول مطلوب",
    nicReq: "الاسم المستعار مطلوب",
    min2: "مطلوب حرفين على الأقل",
    max100: "الحد الأقصى المسموح به هو 100 حرف",
    max250: "الحد الأقصى المسموح به هو 250 حرفًا",
    dobReq: "تاريخ الميلاد مطلوب",
    headlineReq: "العنوان مطلوب",
    chooseProfession: "اختر مهنتك",
    validUrl: "أدخل رابط صحيح من فضلك",
    emailAddressValid: "يرجى التحقق من عنوان البريد الإلكتروني الخاص بك",
    phoneVerify: "يرجى التحقق من رقم هاتفك أولا.",
    approveProfile:
      "تم إنشاء الملف الشخصي بنجاح! يرجى الانتظار حتى يوافق المشرف على ملفك الشخصي",
    profileUpdate: "تم تحديث الملف الشخصي بنجاح",
    nosame: "لا يمكن أن تكون كلمة المرور الجديدة هي نفس كلمة المرور القديمة.",
    oldPass: "كلمة المرور القديمة مطلوبة",
    passChange: "تم تغيير الرقم السري بنجاح",
  },
  orders: {
    order: "الدورات التي تم شراؤها",
    userName: "اسم المستخدم",
    userEmail: "البريد الالكتروني للمستخدم",
    courseName: "عنوان الدورة",
    courseDes: "وصف الدورة التدريبية",
    coursePrice: "سعر الدورة",
    date: "تاريخ",
    ratingReview: "المراجعة والتقييمات",
    ratings: "التقييمات",
    viewdetails: "عرض تفاصيل الطلب",
    subTotal: "المجموع الفرعي",
    taxAmt: "قيمة الضريبة",
    grandTotal: "المجموع الإجمالي",
    studentReview: "مراجعة الطالب",
    yourComment: "تعليقك",
    addComment: "أضف تعليق",
    typeHere: "أكتب هنا",
    userNickName: "اسم المستخدم نيك",
    planName: "اسم الخطة",
    expiry: "Expiry",
  },
  videos: {
    manageProVidoes: "إدارة مقاطع الفيديو الترويجية",
    manageVidoes: "إدارة مقاطع الفيديو",
    manageOrders: "إدارة الطلبات",
    addVideo: "أضف فيديو",
    addProVideos: "إضافة فيديو ترويجي",
    vdo: "فيديو",
    promotional: "الترويجية",
    uploadVideo: "تحميل أشرطة الفيديو",
    proUploaded: "تم تحديث الفيديو الترويجي بنجاح",
    proAdd: "تم إنشاء الفيديو الترويجي بنجاح",
    vdoAdd: "تم إنشاء الفيديو بنجاح",
    vdoUpdated: "تم تحديث الفيديو بنجاح",
    addedVdo: "تمت إضافة مقاطع الفيديو",
  },
  other: {
    settings: "إعدادات",
    contact: "اتصل بالدعم",
    terms: "الأحكام والشروط",
    notification: "إشعار",
    account: "حساب",
    raiseQry: "رفع الاستعلام",
    query: "استفسار",
    broadCast: "إشعار البث",
    earning: "الأرباح",
    accountStatus: "حالة الحساب",
    querySent: "تم إرسال الاستعلام إلى المشرف بنجاح",
    faq: "أسئلة وأجوبة",
    resendSend: "تمت إعادة إرسال Otp بنجاح",
    passSet: "تم إعادة تعيين كلمة المرور بنجاح",
    recieveNotif: "الإخطارات المستلمة",
    ratingDes: "تفاصيل التقييم والمراجعة",
  },
  subscription: {
    planName: "اسم الخطة",
    planValidity: "صلاحية الخطة",
    yearly: "سنوي",
    quartly: "ربع سنوي",
    monthly: "شهريا",
    buyPlan: "شراء خطط العضوية",
    Features: "سمات",
    buy: "شراء الخطة",
    buy1: "يرجى شراء خطة العضوية",
    plan1: "سيحصل المستخدمون على خصومات على الدورات",
    plan2: "القدرة على عرض مقاطع فيديو مجانية أكثر من المستخدمين الآخرين",
  },
};
