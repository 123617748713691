import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import Food from "../../features/buyPlan/Food";
import Photography from "../../features/buyPlan/Photography";
import BothPlans from "../../features/buyPlan/Both";
import { useLazyGetSubscriptionQuery } from "../../services/main";
import { STORAGE_KEYS, getFromStorage, showError } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { Subscription } from "../../types/General";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BuyPlansDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translation = useTranslation();

  const [subscriptionMutaiton] = useLazyGetSubscriptionQuery();

  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const getSubscriptions = async (id: string) => {
    try {
      const response = await subscriptionMutaiton({}).unwrap();
      if (response?.statusCode === 200) {
        const filteredRating = response?.data?.find((item) => item?._id === id);
        setSubscription(filteredRating || null);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSubscriptions(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.subscription.buyPlan}</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/buy-membership-plan");
            }}
          >
            {translation.globals.back}
          </Button>
        </div>

        <Card className="cards">
          <Food subscription={subscription} />
        </Card>
      </div>
    </MainContainer>
  );
};

export default BuyPlansDetail;
