import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import {
  useAddCommentToRatingMutation,
  useGetRatingByIdMutation,
  useLazyGetRatingsQuery,
} from "../../../services/common";
import { AllRating } from "../../../types/General";
import { showError, showToast } from "../../../constants";
import { NumberFormat } from "../../../utils/validations";
import useTranslation from "../../../hooks/Translation";

const ReviewDetails = () => {
  const translation = useTranslation() as any;
  const { id } = useParams();
  const navigate = useNavigate();

  const [rating, setRating] = useState<any>(null);
  const [comment, setComment] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const [getAllRating, { isLoading }] = useLazyGetRatingsQuery();
  const [addComment] = useAddCommentToRatingMutation();

  const [RatingById] = useGetRatingByIdMutation();

  const getRatingById = async (id: string) => {
    try {
      const response = await RatingById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setRating(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getRatingById(id);
    }
  }, []);

  const handleSubmit = async () => {
    setError(true);
    if (!comment) {
      return;
    }
    const body = {
      comment: comment,
    };
    try {
      const response = await addComment({
        id: rating?._id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Comment Added Successfully");
        setComment("");
        setError(false);
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.other.ratingDes}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-review");
            }}
          >
            {translation.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userName}
                      </Typography>
                      <Typography component="p">
                        {rating?.user?.fullName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userNickName}
                      </Typography>
                      <Typography component="p">
                        {rating?.user?.nickName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userEmail}
                      </Typography>
                      <Typography component="p">
                        {rating?.user?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.auth.phone}
                      </Typography>
                      <Typography component="p">
                        {rating?.user?.countryCode
                          ? (rating?.user?.countryCode.includes("+")
                              ? ""
                              : "+") + rating?.user?.countryCode
                          : null}{" "}
                        {rating?.user?.phone || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.courseName}
                      </Typography>
                      <Typography component="p">
                        {rating?.course?.title || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.subTotal}{" "}
                      </Typography>
                      <Typography component="p">
                        {rating?.order?.subTotal
                          ? `$ ${NumberFormat(rating?.order?.subTotal)}`
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.courses.discount}
                      </Typography>
                      <Typography component="p">
                        {rating?.order?.discount || "0"}%
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.taxAmt}
                      </Typography>
                      <Typography component="p">
                        {rating?.order?.taxAmount
                          ? `$ ${NumberFormat(rating?.order?.taxAmount)}`
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.grandTotal}
                      </Typography>
                      <Typography component="p">
                        {rating?.order?.grandTotal
                          ? `$ ${NumberFormat(rating?.order?.grandTotal)}`
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography className="detail_title mn_hdng" component="h2">
                      {translation.orders.studentReview}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <Typography component="p">
                        {rating?.review || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  {rating?.comment ? (
                    <>
                      <Grid item xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          // mb={3}
                        >
                          {translation.orders.yourComment}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                        <Box>
                          <Typography component="p">
                            {rating?.comment || "-"}
                          </Typography>
                        </Box>
                      </Grid>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          // mb={3}
                        >
                          {translation.orders.addComment}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                        <TextField
                          className="text_field"
                          type={"text"}
                          name="lastName"
                          variant="outlined"
                          fullWidth
                          placeholder={translation.orders.typeHere}
                          multiline
                          rows={5}
                          inputProps={{ maxLength: 800 }}
                          value={comment}
                          onChange={(val: any) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              setComment(val.target.value);
                            }
                          }}
                        />
                        {error && !comment ? (
                          <h6 className="err_msg">
                            {translation.error.required}
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} pt={1}>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                          onClick={handleSubmit}
                        >
                          {translation.globals.save}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ReviewDetails;
