import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { DashBoard } from "../../features";
import { BuyMembershipModal, CourseCard } from "../../components";

const DashBoardPage = () => {
  const [openMembershipModal, setOpenMembershipModal] = useState(false);
  return (
    <MainContainer>
      <DashBoard />

      <CourseCard />
      <BuyMembershipModal
        setOpenMembershipModal={setOpenMembershipModal}
        openMembershipModal={openMembershipModal}
      />
    </MainContainer>
  );
};

export default DashBoardPage;
