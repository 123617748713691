import { useMemo } from "react";
import { useAppSelector } from "./store";
import { getSetting } from "../reducers/settingSlice";

function useSetting() {
  const settingObg = useAppSelector(getSetting);

  return useMemo(() => settingObg, [settingObg]);
}

export default useSetting;
