import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AllVideo } from "../types/General";
import { useLazyGetAllVideosQuery } from "../services/main";
import { Loader, STORAGE_KEYS, getFromStorage } from "../constants";
import useTranslation from "../hooks/Translation";
import ShareIcon from "@mui/icons-material/Share";

const CourseCard = () => {
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [open_gallery, setOpen_gallery] = useState(false);
  const [getVideos, { isLoading }] = useLazyGetAllVideosQuery();

  const [videoRes, setVideosRes] = useState<AllVideo[]>([]);
  const [videoPreview, setVideoPreview] = useState<string>("");

  const getAllVideos = async () => {
    try {
      const response = await getVideos({
        size: 7,
        page: 1,
        videoType: 2,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setVideosRes(response?.data?.data || []);
      } else {
        setVideosRes([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVideos();
  }, []);
  return (
    <>
      {videoRes?.length ? (
        <Card style={{ marginTop: 30 }}>
          <Loader isLoad={isLoading} />
          <Typography className="custom_label2">
            {translation.dashboard.freevidos}
          </Typography>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div
                  className="mainCard"
                  onClick={() => {
                    setOpen_gallery(true);
                    setVideoPreview(videoRes[0]?.link);
                  }}
                >
                  {/* <RWebShare
                    data={{
                      text: "Share",
                      url: APP_URL,
                      title: "Share",
                    }}
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <ShareIcon />
                  </RWebShare> */}
                  {videoRes[0]?.link ? (
                    <figure>
                      <video autoPlay muted loop playsInline preload="no">
                        <source
                          src={videoRes[0]?.link ? videoRes[0]?.link : ""}
                          type="video/mp4"
                        />
                      </video>
                    </figure>
                  ) : null}

                  <div
                    className="flex-eng"
                    style={{
                      left: getLang === "en" ? 10 : 0,
                      right: getLang === "ar" ? 10 : 0,
                    }}
                  >
                    <h5>{translation.dashboard.collection}</h5>
                    <h3>
                      {getLang === "en"
                        ? videoRes[0]?.title || ""
                        : videoRes[0]?.title_ar || ""}
                    </h3>
                  </div>
                </div>
              </Grid>

              {videoRes?.length
                ? videoRes?.slice(1, 6).map((item) => {
                    return (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div
                          className="mainCard"
                          onClick={() => {
                            setOpen_gallery(true);
                            setVideoPreview(item?.link);
                          }}
                        >
                          <div
                            className="shareIcon"
                            style={{ padding: "14px" }}
                          >
                            {/* <RWebShare
                              data={{
                                text: "Share",
                                url: APP_URL,
                                title: "Share",
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <ShareIcon />
                            </RWebShare> */}
                          </div>
                          {item?.link ? (
                            <figure>
                              <video
                                autoPlay
                                muted
                                loop
                                playsInline
                                preload="no"
                              >
                                <source src={item?.link} type="video/mp4" />
                              </video>
                            </figure>
                          ) : null}

                          <div className="flex-eng">
                            <h5>{translation.dashboard.collection}</h5>
                            <h3>
                              {getLang === "en"
                                ? item?.title || ""
                                : item?.title_ar || ""}
                            </h3>
                          </div>
                        </div>
                      </Grid>
                    );
                  })
                : null}
              {videoRes?.length === 7 ? (
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div
                    className="mainCard"
                    onClick={() => {
                      setOpen_gallery(true);
                      setVideoPreview(videoRes[6]?.link);
                    }}
                  >
                    {/* <RWebShare
                      data={{
                        text: "Share",
                        url: APP_URL,
                        title: "Share",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <ShareIcon />
                    </RWebShare> */}
                    {videoRes[6]?.link ? (
                      <figure>
                        <video autoPlay muted loop playsInline preload="no">
                          <source
                            src={videoRes[6]?.link ? videoRes[6]?.link : ""}
                            type="video/mp4"
                          />
                        </video>
                      </figure>
                    ) : null}

                    <div className="flex-eng">
                      <h5>{translation.dashboard.collection}</h5>
                      <h3>
                        {getLang === "en"
                          ? videoRes[6]?.title || ""
                          : videoRes[6]?.title_ar || ""}
                      </h3>
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          {/* Gallery */}
          <Modal
            className="modal gallery_modal"
            open={open_gallery}
            onBackdropClick={() => setOpen_gallery(false)}
          >
            {/* <Fade in={open_gallery}> */}
            <Box className="modalBox_body">
              <Box>
                <Button
                  className="close_btn"
                  onClick={() => setOpen_gallery(false)}
                >
                  <CloseIcon />
                </Button>
                <figure style={{ height: 500 }}>
                  <video autoPlay muted loop playsInline preload="yes">
                    <source src={videoPreview} type="video/mp4" />
                  </video>
                </figure>
              </Box>
            </Box>
            {/* </Fade> */}
          </Modal>
        </Card>
      ) : null}
    </>
  );
};

export default CourseCard;
