/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
  Typography,
  Rating,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AllCourse, Category } from "../../types/General";
import {
  useLazyDeleteCourseByIdQuery,
  useLazyGetAllCategoryQuery,
  useLazyGetAllCourseQuery,
  useUpdateCourseStatusMutation,
} from "../../services/main";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import {
  BuyMembershipModal,
  CouponModal,
  Pagination,
  WarnModal,
} from "../../components";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetCsvQuery,
  useLazyGetSettingQuery,
} from "../../services/common";
import useTranslation from "../../hooks/Translation";
import useAuth from "../../hooks/useAuth";
import useSetting from "../../hooks/useSetting";

const ManageCourses = () => {
  const user = useAuth();
  const setting = useSetting();

  const translation = useTranslation() as any;

  const navigate = useNavigate();
  const [getCsvMethod] = useLazyGetCsvQuery();
  const [getSettings] = useLazyGetSettingQuery();
  const [getAllCourse, { isLoading }] = useLazyGetAllCourseQuery();
  const [deleteCourseById, deleteCourseByMutation] =
    useLazyDeleteCourseByIdQuery();
  const [updateStatusMutation] = useUpdateCourseStatusMutation();
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [rating, setRating] = useState<string>("");
  const [priceFilter, setPriceFilter] = useState<string>("");
  const [allCategory] = useLazyGetAllCategoryQuery();
  const [addDiscount, setAddDiscount] = useState<boolean>(false);
  const [openMembershipModal, setOpenMembershipModal] = useState(false);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [allCourse, setAllCourse] = useState<AllCourse[]>([]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handleRatingChange = (event: SelectChangeEvent) => {
    setRating(event.target.value as string);
    setPriceFilter("");
  };

  const handlePriceChange = (event: SelectChangeEvent) => {
    setPriceFilter(event.target.value as string);
    setRating("");
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const updateStatus = async (id: string, status: boolean) => {
    const response = await updateStatusMutation({
      id,
      body: {
        isBlocked: status,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = allCourse.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setAllCourse(updatedData);
      showToast(translation.courses.statusUpdated);
    } else {
      showError(translation.courses.tryAgain);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCourseById({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        handleGetCourse();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleGetCourse = async () => {
    try {
      const response = await getAllCourse({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
        category: category,
        ratings: rating,
        price: priceFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllCourse(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllCourse([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getCsvMethod({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
        category: category,
        ratings: rating,
        price: priceFilter,
      }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const handleClearFilter = () => {
    setDebouncedSearchTerm("");
    setSearchTerm("");
    setCategory("");
    setRating("");
    setPriceFilter("");
  };

  const getSettingsApi = async () => {
    try {
      const res = await getSettings({}).unwrap();
      if (res?.statusCode === 200) {
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  useEffect(() => {
    getSettingsApi();
  }, []);

  useEffect(() => {
    handleGetCourse();
  }, [debouncedSearchTerm, page, category, priceFilter, rating]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || deleteCourseByMutation?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translation.courses.manage} {translation.dashboard.courses}
          </h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_right2">
              <Button
                className="btn btn_primary"
                onClick={() =>
                  user?.isSubscriptionRequired ||
                  setting?.isMerchantSubscriptionEnabled
                    ? user?.isSubscriptionActive
                      ? navigate("/manage-courses/add")
                      : setOpenMembershipModal(true)
                    : navigate("/manage-courses/add")
                }
              >
                {translation.courses.add} {translation.courses.course}
              </Button>
              {/* <Button
                className="btn btn_primary"
                onClick={() => setAddDiscount(true)}
              >
                Add Discount Coupon
              </Button> */}
              <Box className="cards_header_right">
                <Button className="btn btn_primary" onClick={handleExportCsv}>
                  <FileDownloadIcon /> {translation.courses.exportCsv}
                </Button>
              </Box>
            </Box>

            {category ||
            rating ||
            searchTerm ||
            debouncedSearchTerm ||
            priceFilter ? (
              <Box style={{ width: "100%" }}>
                <Typography
                  component="h5"
                  className="clear"
                  onClick={handleClearFilter}
                >
                  {translation.courses.clearFilter}
                </Typography>
              </Box>
            ) : null}

            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={3} xs={12} className="fil-ter">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value="" disabled>
                      {translation.globals.select}{" "}
                      {translation.courses.category}
                    </MenuItem>
                    {categories?.length
                      ? categories?.map((item) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rating}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleRatingChange}
                  >
                    <MenuItem value="" disabled>
                      {translation.globals.select} {translation.courses.rating}
                    </MenuItem>
                    <MenuItem value="1">
                      {translation.courses.lowToHigh}
                    </MenuItem>
                    <MenuItem value="-1">
                      {translation.courses.highToLow}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priceFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handlePriceChange}
                  >
                    <MenuItem value="" disabled>
                      {translation.globals.select}{" "}
                      {translation.courses.priceRange}
                    </MenuItem>
                    <MenuItem value="1">
                      {translation.courses.lowToHigh}
                    </MenuItem>
                    <MenuItem value="-1">
                      {translation.courses.highToLow}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>
                    {translation.courses.course} {translation.globals.title}
                  </TableCell>
                  <TableCell>{translation.globals.description}</TableCell>
                  <TableCell>{translation.courses.category}</TableCell>
                  <TableCell> {translation.courses.price}</TableCell>
                  <TableCell>{translation.courses.rating}</TableCell>
                  <TableCell>{translation.globals.status}</TableCell>
                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allCourse?.length ? (
                  allCourse.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {getLang === "en"
                          ? row?.title || "-"
                          : row?.title_ar || "-"}
                      </TableCell>
                      <TableCell>
                        {row?.description?.length >= 16
                          ? row?.description?.slice(0, 16) + "..."
                          : row?.description || "-"}
                      </TableCell>
                      <TableCell>{row?.category?.name || ""}</TableCell>
                      <TableCell>
                        {`$ ${row?.price}` || translation.globals.free}
                      </TableCell>

                      <TableCell>
                        <Rating
                          readOnly
                          value={Math.round(row?.ratings * 2) / 2}
                          precision={0.5}
                          // value={
                          //   row?.ratings ? Number(row?.ratings.toFixed(2)) : 0
                          // }
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="View">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-courses/view/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={translation.globals.edit}>
                            <IconButton
                              onClick={() =>
                                user?.isSubscriptionRequired ||
                                setting?.isMerchantSubscriptionEnabled
                                  ? user?.isSubscriptionActive
                                    ? navigate(
                                        `/manage-courses/edit/${row?._id}`
                                      )
                                    : setOpenMembershipModal(true)
                                  : navigate(`/manage-courses/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={translation.globals.delete}>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      {translation.globals.no} {translation.dashboard.courses}{" "}
                      {translation.globals.found}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="course"
      />
      <Pagination
        setPage={setPage}
        module={allCourse}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <CouponModal setAddDiscount={setAddDiscount} addDiscount={addDiscount} />
      <BuyMembershipModal
        setOpenMembershipModal={setOpenMembershipModal}
        openMembershipModal={openMembershipModal}
      />
    </MainContainer>
  );
};

export default ManageCourses;
