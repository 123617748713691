/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLazyGetCmsListQuery } from "../../services/common";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getCms, resetCms } from "../../reducers/generalSlice";
import { STORAGE_KEYS, getFromStorage } from "../../constants";
import useTranslation from "../../hooks/Translation";

const PrivacyPolicy = () => {
  const dispatch = useAppDispatch();
  const [getCmsMethod] = useLazyGetCmsListQuery();
  const getLang = getFromStorage(STORAGE_KEYS?.language);
  const translation = useTranslation();

  const getCmsDataApi = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.statusCode === 200) {
        // dispatch(resetCms({ cms: res?.data?.[0] || [] }));
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCmsData = useAppSelector(getCms);
  const cms = getCmsData?.[0];

  useEffect(() => {
    getCmsDataApi();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{}}>
        <figure style={{ paddingTop: "10px", paddingBottom: "20px" }}>
          <img
            src="/static/images/logo.png"
            style={{ width: "250px", height: "85px" }}
          />
        </figure>
        <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
        <div
          style={{ textAlign: "center", fontSize: "22px" }}
          dangerouslySetInnerHTML={{
            __html: cms
              ? getLang === "en"
                ? cms?.privacyPolicy
                : cms?.privacyPolicy_ar
              : translation.error.noData,
          }}
        ></div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
