import { END_POINTS } from "../constants/url";
import {
  AllRating,
  CmsData,
  Dashboard,
  FaqData,
  Graph,
  NotificationBody,
  SettingRes,
} from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetCmsResponse = {
  data: CmsData[] | null;
  statusCode: number;
  message: string;
};

type getRatingResponse = {
  count: number;
  rating: AllRating[];
};

type getFaqResponse = {
  count: number;
  data: FaqData[];
};

type GetParams = {
  size: number;
  page: number;
  query: string;
  videoType?: number;
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqList: builder.query<
      CommonResponseType & { data: getFaqResponse },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.get_faq +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getCmsList: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.get_cms,
        method: "GET",
      }),
    }),
    updateNotification: builder.mutation<
      { statusCode: number; data: User; message: string },
      NotificationBody
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
    accountStatus: builder.mutation<
      { statusCode: number; data: User; message: string },
      { isActive: boolean }
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
    raiseQuery: builder.mutation<
      { statusCode: number; data: User; message: string },
      { query: string }
    >({
      query: (body) => ({
        url: END_POINTS.raiseQuery,
        method: "POST",
        body,
      }),
    }),
    getRatings: builder.query<
      CommonResponseType & { data: getRatingResponse },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.ratings +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getRatingById: builder.mutation<
      CommonResponseType & { data: AllRating },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.ratings}/${id}`,
        method: "GET",
      }),
    }),
    addCommentToRating: builder.mutation<
      CommonResponseType & { data: getRatingResponse },
      { id: string | undefined; body: { comment: string } }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.ratings}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getCsv: builder.query<
      {
        statusCode: number;
        message: number;
        data: string;
      },
      unknown
    >({
      query: ({ size, page, query, category, ratings, price }) => ({
        url:
          END_POINTS.csv +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query +
          "&category=" +
          category +
          "&ratings=" +
          ratings +
          "&price=" +
          price,
        method: "GET",
      }),
    }),
    getSetting: builder.query<
      {
        statusCode: number;
        message: number;
        data: SettingRes;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.settings,
        method: "GET",
      }),
    }),
    getDashboard: builder.query<
      {
        statusCode: number;
        message: number;
        data: Dashboard;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),
    getStudentGraph: builder.query<
      {
        statusCode: number;
        message: string;
        data: Graph;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.studentsGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    getEarningGraph: builder.query<
      {
        statusCode: number;
        message: string;
        data: Graph;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.revenueGraph}?type=${type}`,
        method: "GET",
      }),
    }),
    getNotification: builder.query<
      CommonResponseType & { data: getRatingResponse },
      {}
    >({
      query: () => ({
        url: END_POINTS.notification,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetFaqListQuery,
  useLazyGetCmsListQuery,
  useUpdateNotificationMutation,
  useAccountStatusMutation,
  useRaiseQueryMutation,
  useLazyGetRatingsQuery,
  useAddCommentToRatingMutation,
  useLazyGetCsvQuery,
  useLazyGetSettingQuery,
  useLazyGetDashboardQuery,
  useLazyGetStudentGraphQuery,
  useLazyGetEarningGraphQuery,
  useGetRatingByIdMutation,
  useLazyGetNotificationQuery,
} = commonApi;
