import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { isString } from "../utils/validations";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import useTranslation from "../hooks/Translation";

type props = {
  openMembershipModal: boolean;
  setOpenMembershipModal: Dispatch<SetStateAction<boolean>>;
};
const BuyMembershipModal = ({
  openMembershipModal,
  setOpenMembershipModal,
}: props) => {
  const style = generateResponsiveStyle();
  const navigate = useNavigate();
  const translation = useTranslation();

  return (
    <Modal
      open={openMembershipModal}
      onClose={() => setOpenMembershipModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ position: "relative", marginTop: 20 }}>
          <div className="cross_icn_logout">
            <CloseIcon onClick={() => setOpenMembershipModal(false)} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            {translation.subscription.buy1}
          </Typography>
          <div className="flexdiv" style={{ justifyContent: "center" }}>
            <Button
              className="btn btn_primary"
              onClick={() => {
                setOpenMembershipModal(false);
                navigate("/buy-membership-plan");
              }}
            >
              {translation.subscription.buy}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default BuyMembershipModal;
