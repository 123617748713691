import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Card,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Food from "../../features/buyPlan/Food";
import Photography from "../../features/buyPlan/Photography";
import BothPlans from "../../features/buyPlan/Both";
import { useLazyGetSubscriptionQuery } from "../../services/main";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
} from "../../constants";
import SearchBar from "../../components/SearchBar";
import useTranslation from "../../hooks/Translation";
import { Button } from "@material-ui/core";
import { Subscription } from "../../types/General";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BuyPlans = () => {
  const navigate = useNavigate();
  const [subscriptionArr, setSubscriptionArr] = useState<Subscription[]>([]);
  const translation = useTranslation();
  const [page, setPage] = useState(1);

  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [subscription, { isLoading }] = useLazyGetSubscriptionQuery();

  const getSubscriptions = async () => {
    try {
      const response = await subscription({}).unwrap();
      if (response?.statusCode === 200) {
        setSubscriptionArr(response?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.subscription.buyPlan}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          /> */}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>{translation.subscription.planName}</TableCell>
                  <TableCell>{translation.subscription.planValidity}</TableCell>
                  <TableCell>{translation.globals.price}</TableCell>
                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionArr?.length ? (
                  subscriptionArr.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {getLang === "en"
                          ? row?.name || "-"
                          : row?.name_ar || "-"}
                      </TableCell>
                      <TableCell>
                        {row?.validity === 1
                          ? translation.subscription.yearly
                          : row?.validity === 2
                          ? translation.subscription.quartly
                          : translation.subscription.monthly}
                      </TableCell>
                      <TableCell> ${row?.price || "0"}</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title={translation.globals.view}>
                            <IconButton
                              onClick={() =>
                                navigate(`/buy-membership-detail/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      {translation.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>

      {/* <Pagination
      setPage={setPage}
      module={videoRes}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalPages}
    /> */}
    </MainContainer>
  );
};

export default BuyPlans;
