import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { NumberFormat, isValidInput } from "../../utils/validations";
import {
  useLazyGetAllCategoryQuery,
  useLazyGetAllOrderQuery,
} from "../../services/main";
import { Loader, showError } from "../../constants";
import { AllOrder, AllVideo, Category } from "../../types/General";
import { Pagination } from "../../components";
import moment from "moment";
import useTranslation from "../../hooks/Translation";

const ManageOrders = () => {
  const translation = useTranslation() as any;

  const navigate = useNavigate();
  const [getOrder, { isLoading }] = useLazyGetAllOrderQuery();
  const [allCategory] = useLazyGetAllCategoryQuery();

  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orders, setOrders] = useState<AllOrder[]>([]);
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllOrders = async () => {
    try {
      const response = await getOrder({
        size: 10,
        page: page,
        videoType: 2,
        query: debouncedSearchTerm.trim(),
        category: category,
      }).unwrap();
      if (response?.statusCode === 200) {
        setOrders(response?.data?.orders || []);
        setTotalCount(response?.data?.count);
      } else {
        setOrders([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [debouncedSearchTerm, page, category]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translation.courses.manage} {translation.orders.order}
          </h1>
        </div>
        <Card className="cards">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={12} className="fil-ter">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  className="select_div"
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="" disabled>
                    {translation.globals.select} {translation.courses.category}
                  </MenuItem>
                  {categories?.length
                    ? categories?.map((item) => (
                        <MenuItem key={item?._id} value={item?._id}>
                          {item?.name || ""}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>{translation.orders.userName}</TableCell>
                  <TableCell>{translation.orders.userNickName}</TableCell>
                  <TableCell>{translation.orders.userEmail}</TableCell>
                  <TableCell>{translation.courses.category}</TableCell>
                  <TableCell>{translation.orders.courseName}</TableCell>
                  <TableCell>{translation.orders.coursePrice}</TableCell>
                  <TableCell>{translation.orders.date}</TableCell>
                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.length ? (
                  orders.map((row, i) => (
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>
                        {row.user?.firstName || "-"} {row.user?.lastName || ""}
                      </TableCell>
                      <TableCell> {row?.user?.nickName || "-"}</TableCell>

                      <TableCell> {row?.user?.email || "-"}</TableCell>
                      <TableCell>
                        {row?.course?.category?.name || "-"}
                      </TableCell>

                      <TableCell>{row?.course?.title || ""}</TableCell>
                      <TableCell>
                        {row?.course?.price
                          ? `$${NumberFormat(row?.grandTotal)}`
                          : translation.globals.free}
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format("MM-D-YY / h:mm A")}
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="View">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-orders/view/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      {translation.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        setPage={setPage}
        module={orders}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageOrders;
