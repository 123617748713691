import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Rating,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { useLazyGetRatingsQuery } from "../../services/common";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
} from "../../constants";
import { AllRating } from "../../types/General";
import { Pagination } from "../../components";
import { NumberFormat, isValidInput } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

const ManageReview = () => {
  const translation = useTranslation() as any;

  const navigate = useNavigate();
  const [getAllRating, { isLoading }] = useLazyGetRatingsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [allRating, setAllRating] = useState<AllRating[]>([]);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleGetRating = async () => {
    try {
      const response = await getAllRating({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllRating(response?.data?.rating || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllRating([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    handleGetRating();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.orders.ratingReview}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.globals.serialNo}
                  </TableCell>
                  <TableCell>{translation.orders.userName}</TableCell>
                  <TableCell>{translation.orders.userNickName}</TableCell>
                  <TableCell>{translation.orders.courseName}</TableCell>
                  <TableCell>{translation.orders.coursePrice}</TableCell>
                  <TableCell>{translation.orders.ratings}</TableCell>
                  <TableCell>{translation.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allRating?.length ? (
                  allRating.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row.user?.fullName || ""}</TableCell>
                      <TableCell>{row.user?.nickName || ""}</TableCell>
                      <TableCell>{row?.course?.title || ""}</TableCell>
                      <TableCell>
                        {`$ ${NumberFormat(row?.course?.price)}` || "Free"}
                      </TableCell>

                      <TableCell>
                        <Rating
                          readOnly
                          value={Math.round(row?.rating * 2) / 2}
                          precision={0.5}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title={translation.globals.view}>
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-review/details/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      {translation.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        setPage={setPage}
        module={allRating}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageReview;
