import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isString, useFormik } from "formik";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCategories = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    // formik.setFieldError("message", "");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      // const body = {
      //   message: formik.values.message,
      // };
      // try {
      //   const response = await postReply({
      //     query_id: selectedId,
      //     body,
      //   }).unwrap();
      //   showToast(response?.message || "");
      //   setOpenQueryModal(false);
      //   formik.setFieldValue("message", "");
      //   formik.setFieldError("message", "");
      //   const res = await getQuesriesList({
      //     status: value === 0 ? "PENDING" : "RESOLVED",
      //     page: page,
      //     limit: 10,
      //     query: debouncedSearchTerm.trim(),
      //   }).unwrap();
      //   if (res?.statusCode === 200) {
      //     setQueriesList(res?.data?.ticket || []);
      //     setTotalCount(res?.data?.ticketCount);
      //   } else {
      //     setQueriesList([]);
      //   }
      // } catch (error: any) {
      //   console.log(error);
      // }
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
    // setSelectedId(id);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Grocery"), createData("Dining Out")];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Categories</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Budget Categories" {...a11yProps(0)} />
              <Tab label="Goal Categories" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                {/* <SearchBar /> */}
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-categories/add")}
                  >
                    Add Category
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Category Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>
                          <Switch {...label} defaultChecked size="small" />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => navigate("/manage-categories/add")}
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                {/* <SearchBar /> */}
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => setOpen(true)}
                  >
                    Add Category
                  </Button>
                  <Button
                    className="btn btn_primary"
                    onClick={() =>
                      navigate("/manage-categories/add", { state: "GOAL" })
                    }
                  >
                    Add Sub-category
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>

                      <TableCell>Category Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>Improve my financial health</TableCell>
                        <TableCell>
                          <Switch {...label} defaultChecked size="small" />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate("/manage-categories/details")
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              // onClick={() =>
                              //   navigate("/manage-categories/add", {
                              //     state: "GOAL",
                              //   })
                              // }
                              onClick={handleClickOpen}
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Category</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                type="text"
                placeholder="Add Category"
                fullWidth
                value={formik.values.category}
                onBlur={formik.handleBlur}
                // onChange={(val) => {
                //   if (val.target.value === " " || val.target.value === ".") {
                //   } else if (isString(val.target.value)) {
                //     formik.handleChange(val);
                //   }
                // }}
                // helperText={formik.touched.category && formik.errors.category}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageCategories;
