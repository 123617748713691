import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import LineChart from "../../components/LineChart";
import { useNavigate } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import {
  useLazyGetDashboardQuery,
  useLazyGetEarningGraphQuery,
  useLazyGetSettingQuery,
  useLazyGetStudentGraphQuery,
} from "../../services/common";
import { Dashboard, SettingRes } from "../../types/General";
import { NumberFormat } from "../../utils/validations";
import { CouponModal } from "../../components";
import useTranslation from "../../hooks/Translation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [dashboardMutation] = useLazyGetDashboardQuery();
  const [getStudentGraphMutaion] = useLazyGetStudentGraphQuery();
  const [getEarningGraphMutaion] = useLazyGetEarningGraphQuery();

  const [studentFilter, setStudentFilter] = useState<string>("monthly");
  const [earningFilter, setEarningFilter] = useState<string>("monthly");

  const [studentNames, setStudentNames] = useState<string[]>([]);
  const [studentValues, setStudentValues] = useState<number[]>([]);
  const [earningNames, setEarningNames] = useState<string[]>([]);
  const [earningValues, setEarningValues] = useState<number[]>([]);
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);

  const [getSettings] = useLazyGetSettingQuery();
  const [setting, setSetting] = useState<SettingRes>();
  const getSettingsApi = async () => {
    try {
      const res = await getSettings({}).unwrap();
      if (res?.statusCode === 200) {
        setSetting(res?.data);
      }
    } catch (error: any) {
      console.log("error: ", error);
      // showError(error?.message);
    }
  };

  useEffect(() => {
    getSettingsApi();
  }, []);

  const graphOrderData = {
    labels: studentNames,
    datasets: [
      {
        label: translation.dashboard.users,
        data: studentValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };
  const graphRevenueData = {
    labels: earningNames,
    datasets: [
      {
        label: translation.dashboard.earning,
        data: earningValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  const handleOrderChange = (event: SelectChangeEvent) => {
    setStudentFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  const getDashboard = async () => {
    try {
      const response = await dashboardMutation({}).unwrap();
      if (response?.statusCode === 200) {
        setDashboard(response?.data);
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  const getStudentGraph = async () => {
    try {
      const response = await getStudentGraphMutaion({
        type: studentFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setStudentNames(Object.keys(response?.data));
        setStudentValues(Object.values(response?.data));
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  const getEarningGraph = async () => {
    try {
      const response = await getEarningGraphMutaion({
        type: earningFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setEarningNames(Object.keys(response?.data));
        setEarningValues(Object.values(response?.data));
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  const formatLargeNumber = (number: any) => {
    if (number < 1000) {
      return number;
    } else if (number < 1000000) {
      return (number / 1000).toFixed(2) + "K";
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(2) + "M";
    } else if (number < 1000000000000) {
      return (number / 1000000000).toFixed(2) + "B";
    } else {
      return (number / 1000000000000).toFixed(2) + "T";
    }
  };

  useEffect(() => {
    getStudentGraph();
  }, [studentFilter]);

  useEffect(() => {
    getEarningGraph();
  }, [earningFilter]);

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">{translation.dashboard.dashboard}</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-courses")}
          >
            <SmartDisplayIcon className="svg_icn" />

            <div>
              <h3>{translation.dashboard.totalCourses}</h3>
              <h4 className="mn_hdng">{dashboard?.courses || 0}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item className="cards dashGrid_item">
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.totalEarning}</h3>
              <h4 className="mn_hdng">
                {dashboard?.earnings && setting?.tax
                  ? NumberFormat(dashboard?.earnings)
                  : "0"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-orders")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>{translation.dashboard.totalOrders}</h3>
              <h4 className="mn_hdng">{dashboard?.orders || 0}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              <h3>{translation.dashboard.totalCustomers}</h3>
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    {translation.globals.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translation.dashboard.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translation.dashboard.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translation.dashboard.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translation.dashboard.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              <h3>{translation.dashboard.totalEarning}</h3>
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleEarningChange}
                >
                  <MenuItem value="" disabled>
                    {translation.globals.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translation.dashboard.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translation.dashboard.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translation.dashboard.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translation.dashboard.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
