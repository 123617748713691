import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useEffect, useState } from "react";
import { MainFaq, Pagination } from "../../components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLazyGetFaqListQuery } from "../../services/common";
import { FaqData } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { STORAGE_KEYS, getFromStorage } from "../../constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Faq = () => {
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [totalCount, setTotalCount] = useState<number>(0);

  const [getFaqMethod] = useLazyGetFaqListQuery();

  const [page, setPage] = useState(1);
  const [faqs, setFaqs] = useState<FaqData[]>([]);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getFaqListing = async () => {
    try {
      const res = await getFaqMethod({
        size: 10,
        page: page,
        query: "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFaqListing();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <MainContainer>
      <div className="main_loyout ">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translation?.other.faq}</h1>
        </div>
        <div>
          <Card className="cards">
            <div className="faq_sc">
              {faqs?.length ? (
                faqs?.map((item) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>
                        {getLang === "en"
                          ? item?.question
                          : item?.question_ar || ""}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {getLang === "en"
                          ? item?.answer
                          : item?.answer_ar || ""}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>{translation.error.noFaq}</p>
              )}
            </div>
          </Card>
        </div>
      </div>
      <Pagination
        setPage={setPage}
        module={faqs}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default Faq;
