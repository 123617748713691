export const API_URL =
  "https://cooklifyapi.appgrowthcompany.com/api/v1/merchant/"; //development

export const APP_URL = "https://play.google.com/apps?pli=1";

export const END_POINTS = {
  signUp: "register",
  login: "login",
  forgotPassword: "forgotPassword",
  get_user: "profile",
  update_profile: "updateProfile",
  mediaUpload: "upload",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  logout: "logout",
  verifyPhoneEmail: "verification",
  sendOtp: "sendOtp",
  socialLogin: "socialLogin",

  //cms
  get_cms: "cms",
  get_faq: "faq",

  //notification
  notification: "notifications",
  dashboard: "dashboard",
  revenueGraph: "revenueGraph",
  studentsGraph: "studentsGraph",
  userNotification: "userNotification",

  //course
  course: "course",
  //video
  video: "video",

  //category
  categories: "categories",
  raiseQuery: "raiseQuery",
  ratings: "ratings",
  csv: "exportCourses",
  settings: "settings",
  getOrder: "orders",

  //subscription
  subscription: "subscription",
  buySubscription: "buySubscription",
  cancelSubscription: "cancelSubscription",
};
