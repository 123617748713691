import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import { STORAGE_KEYS, getFromStorage, showError } from "../../../constants";
import { useLazyGetCourseByIdQuery } from "../../../services/main";
import { AllCourse } from "../../../types/General";
import useTranslation from "../../../hooks/Translation";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import { APP_URL } from "../../../constants/url";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseDetails = () => {
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const { id } = useParams();
  const navigate = useNavigate();
  const [CourseById] = useLazyGetCourseByIdQuery();
  const [course, setCourse] = useState<AllCourse | null>(null);
  const [items, setItems] = useState<any>([]);
  const [category, setCategory] = useState<string>("");

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getCourseById = async (id: string) => {
    try {
      const response = await CourseById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setCourse(response?.data?.data);
        setCategory(response?.data?.data?.category?._id);
        setItems(response?.data?.data?.videos || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCourseById(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation?.courses.courseDetail}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-courses");
            }}
          >
            {translation.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <img
                src={
                  course?.coverImage
                    ? course?.coverImage
                    : "/static/images/placeholder_gallery.jpeg"
                }
                alt=""
                style={{
                  width: "300px",
                  height: "200px",
                  marginBottom: 20,
                  background: "grey",
                }}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.globals.title}
                      </Typography>
                      <Typography component="p">
                        {getLang === "en"
                          ? course?.title || "-"
                          : course?.title_ar || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">
                        {`$ ${course?.price}` || "Free"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Total Reviews</Typography>
                      <Typography component="p">
                        {course?.review || "0"}
                      </Typography>
                    </Box>
                  </Grid> */}

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {" "}
                        {translation.courses.category}
                      </Typography>
                      <Typography component="p">
                        {course?.category?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.globals.description}
                      </Typography>
                      <Typography component="p">
                        {course?.description || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <h1
                  className="mn_hdng"
                  style={{ fontSize: "20px", marginTop: 30 }}
                >
                  {translation.videos.addedVdo}
                </h1>

                <div className="card_main">
                  {items &&
                    items.map((item: any, i: number) => (
                      <div key={item?._id} className="card">
                        <div className="shareIcon" style={{ padding: "14px" }}>
                          {id ? (
                            <RWebShare
                              data={{
                                text: "Share",
                                url: APP_URL,
                                title: "Share Course",
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <ShareIcon />
                            </RWebShare>
                          ) : null}
                        </div>
                        {item?.link ? (
                          <figure>
                            <video controls width="100%" height="100%">
                              <source src={item?.link} type="video/mp4" />
                            </video>
                          </figure>
                        ) : (
                          <Skeleton
                            width="100%"
                            height="100%"
                            animation="wave"
                          />
                        )}
                        <div className="desc">
                          <h4>
                            {getLang === "en"
                              ? item?.title || "-"
                              : item?.title_ar || "-"}{" "}
                          </h4>
                          <p>{item?.description || ""} </p>
                          {/* <p className="time">Oct 22, 2023</p> */}
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default CourseDetails;
