import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import { useAppDispatch } from "./hooks/store";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/sign-up" element={<Pages.SignUpPage />} />
      <Route path="forgot-password" element={<Pages.ForgotPasswordPage />} />
      <Route path="verify-otp" element={<Pages.VerifyOtp />} />
      <Route path="reset-password" element={<Pages.ResetPassword />} />
      <Route path="change-password" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/profile-setup" element={<Pages.ProfileSetup />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/settings" element={<Pages.ManageCms />} />
      <Route path="/manage-video" element={<Pages.ManageVideo />} />
      <Route path="/manage-video/add" element={<Pages.AddVideo />} />
      <Route path="/manage-video/edit/:id" element={<Pages.AddVideo />} />
      <Route path="/manage-courses" element={<Pages.ManageCourses />} />
      <Route path="/manage-courses/add" element={<Pages.CourseAdd />} />
      <Route path="/manage-courses/edit/:id" element={<Pages.CourseAdd />} />
      <Route path="/manage-courses/view/:id" element={<Pages.CourseDetail />} />
      <Route path="/manage-orders" element={<Pages.ManageOrders />} />
      <Route path="/manage-orders/view/:id" element={<Pages.OrderDetail />} />
      <Route path="/manage-review" element={<Pages.ManageReview />} />
      <Route path="/manage-promotion" element={<Pages.ManagePromotion />} />
      <Route
        path="/manage-promotion/add"
        element={<Pages.AddPromotionalVideo />}
      />
      <Route
        path="/manage-promotion/edit/:id"
        element={<Pages.AddPromotionalVideo />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />

      <Route path="/buy-membership-plan" element={<Pages.BuyPlans />} />
      <Route
        path="/buy-membership-detail/:id"
        element={<Pages.BuyPlansDetail />}
      />
      <Route
        path="/manage-review/details/:id"
        element={<Pages.ReviewDetails />}
      />
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route path="/terms" element={<Pages.TermsOfUse />} />
    </Routes>
  );
};

export default Routing;
