import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import { useLazyGetAllOrderQuery } from "../../../services/main";
import { AllOrder } from "../../../types/General";
import { STORAGE_KEYS, getFromStorage, showError } from "../../../constants";
import moment from "moment";
import { NumberFormat } from "../../../utils/validations";
import useTranslation from "../../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderDetail = () => {
  const translation = useTranslation() as any;

  const navigate = useNavigate();
  const [getOrder] = useLazyGetAllOrderQuery();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const [orders, setOrders] = useState<AllOrder | null>();
  const handleGetOrder = async (id: string) => {
    try {
      const response = await getOrder({
        page: 1,
        size: 10,
        query: "",
        category: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        const filteredRating = response?.data?.orders.find(
          (item) => item?._id === id
        );
        setOrders(filteredRating || null);
      } else {
        setOrders(null);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      handleGetOrder(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation?.orders.viewdetails}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-orders");
            }}
          >
            {translation.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <img
                src={
                  orders?.course?.coverImage
                    ? orders?.course?.coverImage
                    : "/static/images/placeholder_gallery.jpeg"
                }
                alt=""
                style={{
                  width: "300px",
                  height: "200px",
                  marginBottom: 20,
                  background: "grey",
                }}
              />
            </Grid>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userName}
                      </Typography>
                      <Typography component="p">
                        {orders?.user?.firstName || "-"}{" "}
                        {orders?.user?.lastName || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userNickName}
                      </Typography>
                      <Typography component="p">
                        {orders?.user?.nickName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.userEmail}
                      </Typography>
                      <Typography component="p">
                        {orders?.user?.email || "-"}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.courseName}
                      </Typography>
                      <Typography component="p">
                        {orders?.course?.title || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.courseDes}
                      </Typography>
                      <Typography component="p">
                        {orders?.course?.description || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.date}
                      </Typography>
                      <Typography component="p">
                        {moment(orders?.createdAt).format("MM-D-YY / h:mm A")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.subTotal}
                      </Typography>
                      <Typography component="p">
                        {orders?.subTotal
                          ? `$ ${NumberFormat(orders?.subTotal)}`
                          : "0"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.courses.discount}
                      </Typography>
                      <Typography component="p">
                        {orders?.discount || "0"}%
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.taxAmt}
                      </Typography>
                      <Typography component="p">
                        {orders?.taxAmount
                          ? `$ ${NumberFormat(orders?.taxAmount)}`
                          : "0"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.orders.grandTotal}
                      </Typography>
                      <Typography component="p">
                        {orders?.taxAmount
                          ? `$ ${NumberFormat(orders?.grandTotal)}`
                          : "0"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default OrderDetail;
