import { PropsWithoutRef } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { SIDEBAR_WIDTH, STORAGE_KEYS, getFromStorage } from "../constants";
import QuizIcon from "@mui/icons-material/Quiz";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import RateReviewIcon from "@mui/icons-material/RateReview";
import useTranslation from "../hooks/Translation";
import useAuth from "../hooks/useAuth";
import useSetting from "../hooks/useSetting";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const { id } = useParams();
  const location = useLocation();
  const user = useAuth();
  const setting = useSetting();

  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const drawer = (
    <>
      <Toolbar className="sidebar-logo">
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.dashboard.dashboard}
          />
        </ListItemButton>

        {/* {setting?.isMerchantSubscriptionEnabled ||
        user?.isSubscriptionRequired ? ( */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/buy-membership-plan" ||
            location.pathname === `/buy-membership-detail/${id}`
          }
          onClick={() => navigate("/buy-membership-plan")}
        >
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.subscription.buyPlan}
          />
        </ListItemButton>
        {/* ) : null} */}

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-courses" ||
            location.pathname === "/manage-courses/add" ||
            location.pathname === `/manage-courses/edit/${id}` ||
            location.pathname === `/manage-courses/view/${id}`
          }
          onClick={() => navigate("/manage-courses")}
        >
          <ListItemIcon>
            <SmartDisplayIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={`${translation.courses.manage} ${translation.dashboard.courses}`}
          />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-orders" ||
            location.pathname === `/manage-orders/view/${id}`
          }
          onClick={() => navigate("/manage-orders")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.videos.manageOrders}
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-promotion" ||
            location.pathname === "/manage-promotion/add" ||
            location.pathname === `/manage-promotion/edit/${id}` ||
            location.pathname === `/manage-promotion/view/${id}`
          }
          onClick={() => navigate("/manage-promotion")}
        >
          <ListItemIcon>
            <SwitchVideoIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.videos.manageProVidoes}
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-video" ||
            location.pathname === "/manage-video/add" ||
            location.pathname === `/manage-video/edit/${id}` ||
            location.pathname === `/manage-video/view/${id}`
          }
          onClick={() => navigate("/manage-video")}
        >
          <ListItemIcon>
            <VideoCameraBackIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.videos.manageVidoes}
          />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-review"}
          onClick={() => navigate("/manage-review")}
        >
          <ListItemIcon>
            <RateReviewIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.orders.ratingReview}
          />
        </ListItemButton>

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-notifications"}
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Notifications" />
        </ListItemButton> */}

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/settings"}
          onClick={() => navigate("/settings")}
        >
          <ListItemIcon>
            <SettingsIcon />
            {/* <SummarizeTwoToneIcon />
             */}
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.other.settings}
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/faq"}
          onClick={() => navigate("/faq")}
        >
          <ListItemIcon>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary={translation.other.faq}
          />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            right: 0,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
            right: 0,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
