import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { isString } from "../utils/validations";

type props = {
  addDiscount: boolean;
  setAddDiscount: Dispatch<SetStateAction<boolean>>;
};
const CouponModal = ({ addDiscount, setAddDiscount }: props) => {
  const style = generateResponsiveStyle();
  return (
    <Modal
      open={addDiscount}
      onClose={() => setAddDiscount(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontSize: "19px" }}
        >
          Add Coupon
        </Typography>
      </Box>

      {/* <Typography className="custom_label">Last Name</Typography>
      <TextField
        hiddenLabel
        type={"text"}
        name="lastName"
        variant="outlined"
        fullWidth
        placeholder="Last Name"
        id="lastName"
        className="text_field"
        inputProps={{ maxLength: 100 }}
      /> */}
    </Modal>
  );
};

export default CouponModal;
