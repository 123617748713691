import MainContainer from "../../layout/MainContainer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { RWebShare } from "react-web-share";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import {
  useAddVideoMutation,
  useLazyGetVideoByIdQuery,
  useUpdateVideoByIdMutation,
} from "../../services/main";
import { isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { AllVideo, SettingRes } from "../../types/General";
import { useLazyGetSettingQuery } from "../../services/common";
import useTranslation from "../../hooks/Translation";
import ShareIcon from "@mui/icons-material/Share";
import { APP_URL } from "../../constants/url";

const AddVideo = () => {
  const translation = useTranslation() as any;

  const { id } = useParams();
  const [addVideo, { isLoading }] = useAddVideoMutation();
  const [videoById] = useLazyGetVideoByIdQuery();
  const [updateVideoMutation, updateVideoData] = useUpdateVideoByIdMutation();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [error, setError] = useState(false);
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(false);

  const [getVideo, setGetVideo] = useState<AllVideo | null>(null);
  const [videoDuration, setVideoDuration] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<string>("");

  const navigate = useNavigate();
  const convertDataUrltoImgUrl = async (base64Image: any) => {
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create an image URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
    // Now, 'imageUrl' contains a regular image URL that you can use in your application
    const response = await UploadMedia(blob);
    if (response?.statusCode === 200) {
      // formik.setFieldValue("coverImg", response?.data);
      setThumbnail(response?.data);
    } else {
      showError(response?.message);
    }
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileSizeLimit = Number(setting?.maxVideoSize) * 1024 * 1024; // 6 MB in bytes
      if (file.size > fileSizeLimit) {
        showError(`${translation.error.imageSize} ${setting?.maxVideoSize} MB`);
        return;
      }
      const isWebMSupported = setting?.isWebMSupported ?? false;
      const isMp4Supported = setting?.isMp4Supported ?? false;
      const isAVISupported = setting?.isAVISupported ?? false;
      // Check if the selected file type is supported
      const allowedTypes = [];
      if (isWebMSupported) {
        allowedTypes.push("video/webm");
      }
      if (isMp4Supported) {
        console.log("isMp4Supported: ", isMp4Supported);
        allowedTypes.push("video/mp4");
      }
      if (isAVISupported) {
        console.log("isAVISupported: ", isAVISupported);
        allowedTypes.push("video/x-msvideo");
      }
      if (allowedTypes.length === 0 || !allowedTypes.includes(file.type)) {
        showError(translation.error.imageType);
        return;
      }
      setLoading(true);
      // Create a video element
      const video = document.createElement("video");
      video.preload = "metadata";
      // Set the video source
      video.src = URL.createObjectURL(file);
      // Listen for the loadedmetadata event
      video.addEventListener("durationchange", async () => {
        const duration = video.duration;
        const reqDuration = Number(setting?.maxVideoDuration) * 60;
        console.log(reqDuration, "reqDuration");
        if (duration > reqDuration) {
          showError(
            `${translation.error.imageValidate} ${setting?.maxVideoDuration} ${translation.error.minutes}`
          );
          setLoading(false);
          return;
        }
        setVideoDuration(Math.ceil(duration));
        // Set the currentTime to a suitable position for capturing a thumbnail
        video.currentTime = Math.min(1, duration); // Capture thumbnail after 1 second
        // Wait for the seeked event
        video.addEventListener("seeked", async () => {
          // Create a canvas element
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          // Draw the current frame onto the canvas
          const context = canvas.getContext("2d");
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);
          // Convert the canvas to a data URL representing the thumbnail
          const base64Image = canvas.toDataURL("image/jpeg");
          convertDataUrltoImgUrl(base64Image);
          if (file.type.startsWith("video/")) {
            const res = await UploadVideo(file);
            if (res?.statusCode === 200) {
              formik.setFieldValue("videoLink", res?.data);
              setLoading(false);
            } else {
              setLoading(false);
              showError(res?.message);
            }
          } else {
            // Display an error message for non-image files
            showError(translation.error.validation1);
          }
          // Clean up: remove the video and canvas elements
          video.remove();
          canvas.remove();
        });
      });
      // Append the video element to the document (necessary for triggering events)
      document.body.appendChild(video);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: getVideo?.title || "",
      title_ar: getVideo?.title_ar || "",
      description: getVideo?.description || "",
      videoLink: getVideo?.link || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(translation.error.titlereq)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      title_ar: Yup.string()
        .required(translation.error.titlereq)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      description: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),

      videoLink: Yup.string().required(translation.error.required),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (videoDuration > Number(setting?.maxVideoDuration) * 60) {
        showError(
          `${translation.error.lessThan} ${setting?.maxVideoSize} mb & ${setting?.maxVideoDuration} ${translation.error.minutes}`
        );
        return;
      }

      if (!values?.videoLink) {
        setError(true);
      }

      const body = {
        title: values?.title,
        title_ar: values?.title_ar,
        description: values?.description,
        link: values?.videoLink,
        videoType: 2,
        duration: videoDuration,
        thumbnail: thumbnail,
        language: getLang === "en" ? 1 : 2,
      };

      if (id && id !== "add") {
        try {
          const response = await updateVideoMutation({
            id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.videos.vdoUpdated);
            formik.resetForm();
            navigate("/manage-video");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addVideo(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.videos.vdoAdd);
            formik.resetForm();
            navigate("/manage-video");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getVideoById = async (id: string) => {
    try {
      const response = await videoById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setGetVideo(response?.data);
        setThumbnail(response?.data?.thumbnail || "");
        setVideoDuration(response?.data?.duration || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getVideoById(id);
    }
  }, []);

  const [getSettings] = useLazyGetSettingQuery();
  const [setting, setSetting] = useState<SettingRes>();
  const getSettingsApi = async () => {
    try {
      const res = await getSettings({}).unwrap();
      if (res?.statusCode === 200) {
        setSetting(res?.data);
      }
    } catch (error: any) {
      console.log("error: ", error);
      // showError(error?.message);
    }
  };

  useEffect(() => {
    getSettingsApi();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {" "}
            {id ? translation.globals.edit : translation.globals.add}{" "}
            {translation.videos.vdo}{" "}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-video");
            }}
          >
            {translation.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {translation.videos.uploadVideo}{" "}
                    <span className="asterisk">
                      {translation.globals.asterisk}
                    </span>
                  </Typography>
                  {formik?.values?.videoLink ? (
                    <div className="upload_image_preview2">
                      <video controls width="100%" height="100%">
                        <source
                          src={formik?.values?.videoLink}
                          type="video/mp4"
                        />
                      </video>
                      <div className="shareIcon">
                        {id ? (
                          <RWebShare
                            data={{
                              text: "Share",
                              url: APP_URL,
                              title: "Share Video",
                            }}
                            onClick={() => console.log("shared successfully!")}
                          >
                            <ShareIcon />
                          </RWebShare>
                        ) : null}

                        <CancelIcon
                          style={{ color: "red" }}
                          onClick={() => formik.setFieldValue("videoLink", "")}
                        />
                      </div>
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file-vdo">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file-vdo"
                          type="file"
                          inputProps={{
                            accept: "video/*",
                          }}
                          onChange={handleVideoUpload}
                          onClick={(
                            event: React.MouseEvent<HTMLInputElement>
                          ) => {
                            const inputElement =
                              event.target as HTMLInputElement;
                            inputElement.value = "";
                          }}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !formik.values.videoLink ? (
                    <h6 className="err_msg">{translation.error.required}</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={4} sm={4} xs={12} />
                <Grid item lg={6} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {translation.globals.title} {translation.globals.english}
                    <span className="asterisk">
                      {translation.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="title"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.globals.title}
                    id="title"
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>

                <Grid item lg={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.globals.title} {translation.globals.arabic}
                    <span className="asterisk">
                      {translation.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="title_ar"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.globals.title}
                    id="title_ar"
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title_ar}
                    helperText={
                      formik.touched.title_ar && formik.errors.title_ar
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.globals.description}{" "}
                    <span className="asterisk">
                      {translation.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    placeholder={translation.globals.description}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="description"
                    variant="outlined"
                    id="description"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
              </Grid>

              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translation.globals.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddVideo;
